import { Card as AntdCard, CardProps as AntdCardProps } from 'antd';
import styled from '@emotion/styled';
import colors from '@/theme/colors.module.scss';
import omit from 'lodash/omit';
import { memo } from 'react';

interface GTGSCardProps extends AntdCardProps {
  container?: boolean;
  grey?: boolean;
}

const GTGSCardStyle = styled((props: GTGSCardProps) => {
  return <AntdCard {...omit(props, ['container', 'grey'])} />;
})<GTGSCardProps>`
  .ant-card-body {
    ${(props: GTGSCardProps) => {
      return props.size === 'small' && `padding: 16px;`; // Antd not support design token
    }}
    ${(props: GTGSCardProps) => props.container && `padding: 36px;`}
     ${(props: GTGSCardProps) => props.grey && `padding: 24px;`}
    @media (max-width: 767px) {
      ${(props: GTGSCardProps) => props.container && `padding: 24px;`}
    }
  }
  ${(props: GTGSCardProps) =>
    props.grey && `background: ${colors.colorBaseLighter020};`}
`;

const _GTGSCard = ({
  children,
  container = false,
  grey = false,
  ...rest
}: GTGSCardProps) => {
  return (
    <GTGSCardStyle container={container} grey={grey} {...rest}>
      {children}
    </GTGSCardStyle>
  );
};

const GTGSCard = memo(_GTGSCard);

export { GTGSCard, type GTGSCardProps };
