import colors from '@/theme/colors.module.scss';
import { Tag, TagProps, Typography } from 'antd';
import classNames from 'classnames';
import styles from './GTGSStatus.module.scss';

enum EStatus {
  PENDING = 'PENDING',
  DANGER = 'DANGER',
  NEUTRAL = 'NEUTRAL',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
}

type StatusProps = keyof typeof EStatus;

const StatusMap = {
  [EStatus.PENDING]: {
    color: colors.colorWarningDark,
    background: colors.colorSurfaceSemanticWarning,
    title: 'Pending',
  },
  [EStatus.DANGER]: {
    color: colors.colorDangerDark,
    background: colors.colorSurfaceSemanticDanger,
    title: 'Danger',
  },
  [EStatus.INFO]: {
    color: colors.colorInfoDark,
    background: colors.colorSurfaceSemanticInfo,
    title: 'Info',
  },
  [EStatus.NEUTRAL]: {
    color: colors.colorTextPrimary,
    background: colors.colorSurfaceSecondaryDefault,
    title: 'Neutal',
  },
  [EStatus.SUCCESS]: {
    color: colors.colorSuccessDark,
    background: colors.colorSuccessLightest,
    title: 'Success',
  },
};

interface IStatusProps extends TagProps {
  status: StatusProps | string;
  mapStatus?: Record<string, StatusProps>;
  text?: string;
  borderRound?: boolean;
  size?: 'small' | 'large';
}

const GTGSStatus = ({
  status,
  mapStatus,
  text,
  className,
  size = 'large',
  borderRound,
  ...rest
}: IStatusProps) => {
  const mappedStatus = mapStatus?.[status] ? mapStatus[status] : status;
  const { color, background, title } =
    StatusMap[mappedStatus?.toUpperCase()] || {};
  if (!color || !background || !title) {
    return null;
  }

  const isSmallSize = size === 'small';
  const content = text ?? title;

  return (
    <Tag
      className={classNames(
        styles.tag,
        {
          [styles.smallSize]: isSmallSize,
        },
        className
      )}
      style={{ color, background }}
      bordered={false}
      {...rest}
    >
      {isSmallSize ? (
        content
      ) : (
        <Typography.Text style={{ color: color }}>{content}</Typography.Text>
      )}
    </Tag>
  );
};

export { GTGSStatus, EStatus, StatusMap, type StatusProps };
