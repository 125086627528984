import { memo } from 'react';
import {
  EmptyPatternIcon,
  MagnifyingGlassIcon,
  FilesIcon,
  WalletIcon,
} from '../GTGSIcon';

const iconStyle: React.CSSProperties = {
  fontSize: 80,
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  color: '#fff',
};

const icon = {
  transaction: FilesIcon,
  search: MagnifyingGlassIcon,
  account: WalletIcon,
};
type GTGSEmptyBoxProps = {
  size?: number;
  type: keyof typeof icon;
};
// TODO
const _GTGSEmptyBox = (props: GTGSEmptyBoxProps) => {
  const { type, size = 160 } = props;
  const Icon = icon[type];
  return (
    <div style={{ position: 'relative' }}>
      <EmptyPatternIcon style={{ fontSize: size }} />
      <Icon style={{ ...iconStyle, fontSize: size / 2 }} />
    </div>
  );
};

const GTGSEmptyBox = memo(_GTGSEmptyBox);

export { GTGSEmptyBox, type GTGSEmptyBoxProps };
