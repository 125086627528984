import { Select } from 'antd';
import { memo } from 'react';
import styles from './FlagSlider.module.scss';

export const FlagSlider = memo(_FlagSlider);

function _FlagSlider() {
  const locale =
    typeof window !== 'undefined' && window.localStorage.getItem('lang');

  return (
    <div className={styles.selectContainer}>
      <Select
        dropdownAlign={{ offset: [150, 10] }}
        defaultValue={locale ?? 'en'}
        suffixIcon=""
        variant='borderless'
        options={[{
          value: 'en',
          label: 'English',
          icon: <img src="/images/flags/en.png" alt="English" />,
        },
        {
          value: 'cn',
          label: 'China',
          icon: <img src="/images/flags/cn.png" alt="China" />,
        }
      ]}
        popupClassName={styles.selectDropdown}
        popupMatchSelectWidth={150}
        size="large"
      />
    </div>
  );
}
