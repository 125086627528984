import {
  Divider,
  Flex,
  InputNumber,
  InputNumberProps,
  Space,
  Typography,
} from 'antd';
import styles from './styles.module.scss';
import { memo } from 'react';

interface GTGSInputAmountProps extends InputNumberProps {
  currencyPosition?: 'start' | 'end';
  currency?: 'USD' | 'KHR';
}

const _GTGSInputAmount = ({
  currency,
  currencyPosition = 'start',
  ...rest
}: GTGSInputAmountProps) => {
  // const formattedValue = new Intl.NumberFormat().format(Number(rest.value));
  return (
    <Space.Compact className={styles.inputWithSelect}>
      <InputNumber
        stringMode
        controls={false}
        variant="borderless"
        style={{ order: currencyPosition === 'end' ? 0 : 2, width: '100%' }}
        formatter={(value) => {
          return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }}
        maxLength={26}
        precision={currency === 'KHR' ? 0 : 2}
        step={currency === 'KHR' ? 1 : 0.01}
        {...rest}
      />
      <Divider
        type="vertical"
        style={{
          height: '24px',
          width: '2px',
          margin: 'auto',
          borderInlineWidth: 2,
          order: 1,
        }}
      />
      <Flex
        align="center"
        justify="center"
        style={{ width: 60, order: currencyPosition === 'end' ? 2 : 0 }}
      >
        <Typography.Text>{currency}</Typography.Text>
      </Flex>
    </Space.Compact>
  );
};

const GTGSInputAmount = memo(_GTGSInputAmount);

export { GTGSInputAmount, type GTGSInputAmountProps };
