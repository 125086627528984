import {
  Breadcrumb as AntdBreadcrumb,
  BreadcrumbProps as AntdBreadcrumbProps,
} from 'antd';
import { Link } from 'react-router-dom';
import { trans } from '@/i18n';
import { memo } from 'react';

export const BREADCRUMDS = {
  DASHBOARD: {
    title: trans('common.breadcumds.dashboard'),
    path: '/',
  },
  ACCOUNTS: {
    title: trans('common.breadcumds.accounts'),
    path: '/account',
  },
  ACCOUNT_DETAILS: {
    title: trans('common.breadcumds.accountDetails'),
    path: '/account/:id',
  },
  EARLY_CLOSURE_DEPOSIT: {
    title: trans('common.breadcumds.earlyClosureTermDeposit'),
    path: '/deposit-early-closure/:id',
  },
  DOMESTIC_TRANSFER: {
    title: trans('common.breadcumds.domesticTransfer'),
    path: '/pay-transfer/domestic-transfer',
  },
  CREATE_DOMESTIC_TRANSFER: {
    title: trans('common.breadcumds.newDomesticTransfer'),
    path: '/pay-transfer/domestic-transfer-create',
  },
  NCS: {
    title: trans('common.breadcumds.reviewAndConfirm'),
    path: '/pay-transfer/domestic-transfer-review',
  },
  NCS_UPLOAD: {
    title: trans('common.breadcumds.uploadDocument'),
    path: '/pay-transfer/domestic-transfer-upload',
  },
  PAYROLL: {
    title: trans('common.breadcumds.payroll'),
    path: '/pay-transfer/payroll',
  },
  CREATE_PAYROLL: {
    title: trans('common.breadcumds.newPayrollPayment'),
    path: '/pay-transfer/create-payroll',
  },
  EDIT_PAYROLL: {
    title: trans('common.breadcumds.initiatedResult'),
    path: '/pay-transfer/edit-payroll',
  },
  REVIEW_PAYROLL: {
    title: trans('common.breadcumds.reviewConfirm'),
    path: '/pay-transfer/review-payroll',
  },
  NEW_PAYROLL_DETAIL: {
    title: trans('common.breadcumds.newPayrollPaymentDetails'),
    path: '/pay-transfer/new-payroll-detail',
  },
  BENEFICIARY: {
    title: trans('common.breadcumds.beneficiary'),
    path: '/pay-transfer/beneficiary',
  },
  NEW_BENEFICIARY: {
    title: trans('common.breadcumds.newBeneficiary'),
    path: '/pay-transfer/beneficiary-create',
  },
  TRANSACTION_HISTORY: {
    title: trans('common.breadcumds.transactionHistory'),
    path: '/account/transaction-history',
  },
  BILL_PAYMENT: {
    title: trans('common.breadcumds.billPayment'),
    path: '/pay-transfer/bill-payment',
  },
  MOBILE_TOPUP: {
    title: trans('common.breadcumds.mobileTopup'),
    path: '/pay-transfer/bill-payment/MOBILE_TOPUP',
  },
  MOBILE_TOPUP_REVIEW: {
    title: trans('common.breadcumds.reviewConfirm'),
    path: '/pay-transfer/bill-payment/MOBILE_TOPUP_REVIEW',
  },
  NEW_UTILITIES_PAYMENT: {
    title: trans('common.breadcumds.newUtilitiesPayment'),
    path: '/pay-transfer/bill-payment/UTILITIES',
  },
  TRANSACTION_DETAIL: {
    title: trans('common.breadcumds.transactionDetails'),
    path: '/account/transaction-history/:id',
  },
  INTERNATIONAL_TRANSFER: {
    title: trans('common.breadcumds.internationalTransfer'),
    path: '/pay-transfer/international-transfer',
  },
  INTERNATIONAL_TRANSFER_CREATE: {
    title: trans('common.breadcumds.internationalTransfer'),
    path: '/pay-transfer/international-transfer/create',
  },
  INTERNATIONAL_ADD_BENE: {
    title: trans('common.breadcumds.addBeneficiary'),
    path: '/pay-transfer/international-transfer/new-beneficiary',
  },
  INTERNATIONAL_EDIT_BENE: {
    title: trans('common.breadcumds.editBeneficiary'),
    path: '/pay-transfer/international-transfer/edit-beneficiary',
  },
  NOTIFICATION_SETTING: {
    title: trans('common.breadcumds.notificationSetting'),
    path: '/account/notification-setting',
  },
};

interface GTGSBreadcrumbProps extends AntdBreadcrumbProps {
  backComponent?: any;
}

const _GTGSBreadcrumb = ({ items, backComponent, ...rest }: GTGSBreadcrumbProps) => {
  function itemRender(currentRoute, _, items, __) {
    const isLast = currentRoute?.path === items[items.length - 1]?.path;
    const isBackItem = currentRoute?.path === items[items.length - 2]?.path;
    return isLast ? (
      <span>{currentRoute.title}</span>
    ) : isBackItem && backComponent ? (
      backComponent
    ) : (
      <Link to={currentRoute?.path}>{currentRoute.title}</Link>
    );
  }

  return <AntdBreadcrumb items={items} itemRender={itemRender} {...rest} />;
};


const GTGSBreadcrumb = memo(_GTGSBreadcrumb);

export { GTGSBreadcrumb, type GTGSBreadcrumbProps };
//Initiated Result
//Review & Confirm
