import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as WarningSvg } from '../svg/Warning.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const WarningIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={WarningSvg} {...props} />;
};

export default WarningIcon;
