import { Empty as AntdEmpty, EmptyProps as AntdEmptyProps } from 'antd';
import { memo } from 'react';
import { GTGSEmptyBox, GTGSEmptyBoxProps } from './GTGSEmptyBox';
import { GTGSButton } from '../GTGSButton/GTGSButton';

interface GTGSEmptyProps extends AntdEmptyProps {
  type?: 'transaction' | 'search' | 'account';
  buttonProps?: any;
  emptyImageProps?: Partial<GTGSEmptyBoxProps>;
}

function _GTGSEmpty(props: GTGSEmptyProps) {
  const {
    children,
    type = 'transaction',
    buttonProps,
    emptyImageProps,
    ...rest
  } = props;
  return (
    <AntdEmpty {...rest} image={<GTGSEmptyBox type={type} {...emptyImageProps} />}>
      {buttonProps && <GTGSButton {...buttonProps}>{buttonProps.children}</GTGSButton>}
    </AntdEmpty>
  );
}

const GTGSEmpty = memo(_GTGSEmpty);

export { GTGSEmpty, type GTGSEmptyProps };
