import { Loading } from '@/components';
import loadable from '@loadable/component';
import { routesPath } from './constants';

const CasesPage = loadable(() => import('@/pages/Cases'), {
  fallback: <Loading />,
});

// --------------------------------------------------------------------------- ROUTER
const RouterBookmark = {
  path: routesPath.BOOKMARK,
  children: [
    {
      path: routesPath.BOOKMARK,
      element: <CasesPage />,
    },
  ],
};

export default RouterBookmark;
