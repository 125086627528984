import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as MagnifyingGlassSvg } from '../svg/MagnifyingGlass.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const MagnifyingGlassIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={MagnifyingGlassSvg} {...props} />;
};

export default MagnifyingGlassIcon;
