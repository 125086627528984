import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as CalendarBlankSvg } from '../svg/CalendarBlank.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const CalendarBlankIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={CalendarBlankSvg} {...props} />;
};

export default CalendarBlankIcon;
