import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as CheckCircleSvg } from '../svg/CheckCircle.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const CheckCircleIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={CheckCircleSvg} {...props} />;
};

export default CheckCircleIcon;
