/* eslint-disable @typescript-eslint/no-unused-vars */
import '@/styles/app.styles.scss';
import '@/styles/colors.scss';
import '@/styles/styles.scss';
import 'antd/dist/reset.css';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import ErrorBoundary from './pages/Errors/ErrorBoundary';
import reportWebVitals from './reportWebVitals';
import router from './router';
import UserService from './services/UserService';

const root = ReactDOM.createRoot(document.getElementById('root'));

const renderAppLoading = () => {
  root.render(
    JSON.parse(localStorage.getItem('isLoggedIn')) === 'true' ? <></> : null
  );
};

const renderApp = () => {
  root.render(
    <React.StrictMode>
      <ErrorBoundary>
        <I18nextProvider i18n={i18n} defaultNS={'lang'}>
          <RouterProvider router={router} />
        </I18nextProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

// renderApp();
UserService.initKeycloak(renderAppLoading, renderApp);
reportWebVitals();
