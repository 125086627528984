import iconEmpty from '@/assets/images/emptyv2.svg';
import { Drawer, Empty, Flex, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GTGSButton, GTGSInput, GTGSIconN } from '../GTGS';
import { MagnifyingGlassIcon, WalletIcon } from '../GTGS/GTGSIcon';
import { searchFeatures } from './data';

const MobileSearch = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState(searchFeatures.slice(0, 5));
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <Button
        icon={<MagnifyingGlassIcon />}
        type="link"
        size="large"
        style={{ color: '#000' }}
        onClick={() => {
          showDrawer();
        }}
      /> */}
      <MagnifyingGlassIcon
        onClick={() => {
          showDrawer();
        }}
      />
      <Drawer
        // style={{ backgroundColor: '#EAEDF4' }}
        title={
          <GTGSInput
            prefix={<WalletIcon /> }//TODO:<BICIcon name="search" style={{ marginRight: 8 }} />
            allowClear
            autoFocus
            onChange={(e) => {
              const inputElement = e.target as HTMLInputElement;
              setSearchText(inputElement?.value);
            }}
            value={searchText}
            placeholder="Search..."
          />
        }
        onClose={onClose}
        open={open}
        width={'100%'}
        height={'calc(100% - 78px)'}
        placement="bottom"
        styles={{
          header: { border: 'none', paddingBottom: 0 },
        }}
        closeIcon={null}
        extra={
          <GTGSButton type="link" onClick={onClose} style={{ marginLeft: 8 }}>
            {t('common.cancel')}
          </GTGSButton>
        }
      >         
      </Drawer>
    </>
  );
};

export default MobileSearch;
