import { Grid } from 'antd';

const { useBreakpoint } = Grid;

const useResponsive = () => {
  const screens = useBreakpoint();
  return screens;
};

export default useResponsive;
