export const INIT_PERSONAL_PROFILE = {
  userId: "",
  customerId: "",
  userName: "",
  firstName: "",
  lastName: "",
  firstNameKhr: null,
  lastNameKhr: null,
  identifyType: null,
  idNumber: "",
  dob: "",
  gender: "",
  phoneNumber: "",
  address: null,
  email: "",
  jobTitle: "",
  jobRoleId: "",
  description: null,
  issuedDate: "",
  expiredDate: "",
  createdAt: "",
  status: "",
  lastLogin: "",
  gbshortName: null
}

export const INIT_COMPANY_PROFILE = {
  id: "",
  businessName: "",
  businessNameKhmer: null,
  businessPhoneNumber: "",
  emailAddress: "",
  cifNo: "",
  customerMnemonic: "",
  companyTin: null,
  customerCategory: null,
  customerType: null,
  customerContactSource: null,
  segmentType: null,
  businessRegistrationDate: "",
  businessRegistrationNumber: "",
  servicePackage: null,
  issueBankBranch: null,
  headOfficeAddress: null,
  headOfficeCountryCode: "",
  headOfficeCountry: null,
  headOfficeProvinceCode: "",
  headOfficeProvince: null,
  headOfficeDistrictCode: "",
  headOfficeDistrict: null,
  fullHeadOfficeAddress: null,
  businessAddress: null,
  businessCountryCode: null,
  businessCountry: "",
  businessProvinceCode: null,
  businessProvince: null,
  businessDistrictCode: null,
  businessDistrict: null,
  fullBusinessAddress: null,
  customerStatus: "",
  typeOfSector: "",
  registeredCountry: null,
  creditRiskRating: "",
  gBShortName: null,
  issueBankBranchCode: null,
  establishmentDate: "",
  companyType: "",
  industry: "",
  agreementStatus: null,
  onboardDate: "",
  contacts: [],
  documents: [],
  status: ""
}
