import UserService from './UserService';

// roles

export const getAccessToken = (): string | undefined => {
  return UserService.getToken();
};

const isAuthenticated = () => Boolean(getAccessToken());

const getRefreshToken = (): string | undefined => {
  return UserService.getToken();
};

// retry refresh token
const RETRY_REFRESH_TOKEN_STORAGE_KEY = 'retry-refresh-token';
let retryRefreshToken =
  localStorage.getItem(RETRY_REFRESH_TOKEN_STORAGE_KEY) === 'true';

const setRetryRefreshToken = (value: boolean) => {
  retryRefreshToken = value;
  localStorage.setItem(RETRY_REFRESH_TOKEN_STORAGE_KEY, String(value));
};

const isTokenRefreshed = () => retryRefreshToken;

const authService = {
  isAuthenticated,
  getAccessToken,
  getRefreshToken,
  setRetryRefreshToken,
  isTokenRefreshed,
};

export default authService;
