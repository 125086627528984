import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as HamburgerSvg } from '../svg/hamburger-menu.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const HamburgerIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={HamburgerSvg} {...props} />;
};

export default HamburgerIcon;
