import { Tabs as AntdTabs, TabsProps as AntdTabsProps } from 'antd';
import styled from '@emotion/styled';
import colors from '@/theme/colors.module.scss';
import { GTGSTabsProps } from './GTGSTabs';
import omit from 'lodash/omit';

export const TabsStyle = styled((props: GTGSTabsProps) => {
  return <AntdTabs {...omit(props, ['buttonType'])} />;
})<AntdTabsProps>`
  .ant-tabs-tab {
    min-width: 144px;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 40px;
  }

  ${(props: GTGSTabsProps) =>
    props.buttonType &&
    `.ant-tabs-nav-list {
        gap: 16px;
    }
    .ant-tabs-tab {
        background: ${colors.colorSurfacePrimaryDefault};
        display: block; 
        border-radius: 1000px;
        border: 1px solid ${colors.colorBaseGrayscale300}
    }

    .ant-tabs-tab-active {
        border: none;
        background-color: ${colors.colorBasePrimary500};
        border: 2px solid ${colors.colorBasePrimary500}
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${colors.colorTextReversed}
    }

    .ant-space {
        column-gap: 0
    }

    .ant-tabs-content-holder {
        margin-top: 24px;
    }
  `}

  .ant-tabs-tab-btn {
    text-align: center;
  }
`;
