
import { Loading } from '@/components';
import loadable from '@loadable/component';
import { routesPath } from './constants';

const RequestsPage = loadable(() => import('@/pages/Requests'), {
  fallback: <Loading />,
});
// --------------------------------------------------------------------------- ROUTER
const RouterMarketing = {
  path: routesPath.MARKETING,
  children: [
    {
      path: routesPath.MARKETING,
      element: <RequestsPage />,
    }
  ]
};

export default RouterMarketing;
