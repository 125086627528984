import { truncate } from '@/helpers';
import { useResponsive } from '@/hooks';
import UserService from '@/services/UserService';
import { useProfile } from '@/store/profileStore';
import colors from '@/theme/colors.module.scss';
import type { MenuProps } from 'antd';
import { Avatar, Divider, Dropdown, Flex } from 'antd';
import { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GlobalSearch from '../GlobalSearch';
import {
  SignOutIcon,
  UserCircleIcon
} from '../GTGS/GTGSIcon';
import CaretDownIcon from '../GTGS/GTGSIcon/icons/CaretDownIcon';
import styles from './styles.module.scss';
import { GTGSTypography } from '../GTGS';

const Userinfo = () => {
  const navigate = useNavigate();
  const responsive = useResponsive();
  const { xs } = responsive;
  const { t } = useTranslation();
  const logout = () => {
    localStorage.setItem('isLoggedIn', 'false');
    UserService.doLogout();
  };

  const [companyProfileData] = useProfile((state) => [state.companyProfile]);

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'logout':
        return logout();
      case 'transfer-limit':
        return navigate('pay-transfer/limit');
      case 'notification-settings':
        return navigate('account/notification-setting');
      default:
        return navigate(`/${key}`);
    }
  };

  const iconStyle: CSSProperties = {
    fontSize: 16,
    color: colors.colorTextSecondary,
  };

  // TODO: update
  const items: MenuProps['items'] = [
    // {
    //   label: t('header.personalProfile'),
    //   key: 'personal-profile',
    //   icon: <UserIcon style={iconStyle} />,
    // },
    // {
    //   label: t('header.companyProfile'),
    //   key: 'company-profile',
    //   icon: <BuildingsIcon style={iconStyle} />,
    // },
    // {
    //   label: t('header.securityAndPassword'),
    //   key: 'change-password',
    //   icon: <LockSimpleIcon style={iconStyle} />,
    // },

    // {
    //   label: t('header.transactionLimit'),
    //   key: 'transaction-limit',
    //   icon: <ProhibitInsetIcon style={iconStyle} />,
    // },
    // {
    //   label: t('header.notificationSetting'),
    //   key: 'notification-settings',
    //   icon: <BellIcon style={iconStyle} />,
    // },

    {
      label: t('header.logout'),
      key: 'logout',
      icon: <SignOutIcon style={iconStyle} />,
    },
  ];

  const userName = useMemo(() => {
    const profile = UserService.getTokenParsed();
    return profile?.name || profile?.preferred_username || '';
  }, []);

  const name = companyProfileData?.gBShortName || '';

  return (
    <Flex gap={16} align="center" style={{ height: '100%' }}>
      {/* <Divider type="vertical" style={{ height: '24px' }} /> */}

      <Dropdown
        menu={{ items, onClick }}
        className={styles.dropdown}
        overlayClassName={styles.overlayClassName}
      >
        <Flex align="center" gap={8}>
          {/* <Image
            preview={false}
            width={32}
            height="auto"
            src={<UserCircleIcon/>}
            alt="logo"
          /> */}
          <Avatar
            size={32}
            src={<UserCircleIcon style={{ fontSize: 32 }}/>}
            alt="avatar"
          />
          {!xs && (
            <Flex gap={12}>
              <Flex vertical gap={0}>
                <GTGSTypography.Text size="sm" strong>
                  {truncate(userName || "User name", 40)}
                </GTGSTypography.Text>
                <GTGSTypography.Text size="xs">
                  {truncate(name || "title", 40)}
                </GTGSTypography.Text>
              </Flex>
              <CaretDownIcon style={{ fontSize: 16 }} />
            </Flex>
          )}
        </Flex>
      </Dropdown>
    </Flex>
  );
};

export default Userinfo;
