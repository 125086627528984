import variables from '../../../theme/colors.module.scss';

// ===========================================================================
// User file: [`colors.module.scss`] view code color
// ===========================================================================

const {
  colorBlue,
  colorGreen,
  colorOrange,
  colorYellow,
  colorMagenta,
  colorViolet,
  colorDarkBlue,
  colorBlack,
  colorBasePrimary,
  colorPrimary70,
  colorPrimary50,
  colorPrimary30,
  colorPrimary10,
  colorBaseSecondary,
  colorSecondary70,
  colorSecondary50,
  colorSecondary30,
  colorSecondary10,
  colorBaseDark,
  colorDark70,
  colorDark50,
  colorDark30,
  colorDark10,
  colorBaseWhite,
  colorWhite70,
  colorWhite50,
  colorWhite30,
  colorWhite10,
  colorBaseInfo,
  colorInfo30,
  colorPending30,
  colorSuccess30,
  colorError30,
  colorTextPrimary,
  colorTextSecondary,
  colorTextTertiary,
  colorTextLink,
  colorTextDestructive,
  colorTextReversed,
  colorTextButtonDefault,
  colorTextInfo,
  colorTextWarning,
  colorTextDanger,
  colorTextSuccess,
  colorButtonPrimary,
  colorButtonPrimaryHover,
  colorButtonPrimaryActive,
  colorButtonSecondary,
  colorButtonSecondaryHover,
  colorButtonSecondaryActive,
  colorButtonDisabled,
  colorStatusSuccess100,
  colorStatusSuccess60,
  colorStatusSuccess30,
  colorStatusInfo100,
  colorStatusInfo60,
  colorStatusInfo30,
  colorStatusWarning100,
  colorStatusWarning60,
  colorStatusWarning30,
  colorStatusError100,
  colorStatusError60,
  colorStatusError30,
  colorStatusPending100,
  colorStatusPending60,
  colorStatusPending30,
  colorSurfacePrimary,
  colorSurfacePrimaryHover,
  colorSurfacePrimaryActive,
  colorSurfacePrimarySelect,
  colorSurfacePrimaryDisable,
  colorSurfaceSecondary,
  colorSurfaceSecondaryHover,
  colorSurfaceSecondaryActive,
  colorSurfaceSecondarySelect,
  colorSurfaceSecondaryDisable,
  colorSurfaceTertiary,
  colorSurfaceTertiaryHover,
  colorSurfaceTertiaryActive,
  colorSurfaceTertiarySelect,
  colorSurfaceTertiaryDisable,
  colorBorder1,
  colorBorder2,
  colorBorder3,
  colorBorderDark,
  colorMainBackground,
  colorSuccessDarkest,
  colorSuccessDarker,
  colorSuccessDark,
  colorSuccess400Default,
  colorSuccessLight,
  colorSuccessLighter,
  colorSuccessLightest,
  colorInfoDarkest,
  colorInfoDark,
  colorInfoDarker,
  colorInfoNormal,
  colorInfoLight,
  colorInfoLighter,
  colorInfoLightest,
  colorDangerDarkest,
  colorDangerDarker,
  colorDangerDark,
  colorDangerNormal,
  colorDangerLight,
  colorDangerLighter,
  colorDangerLightest,
  colorWarningDarkest,
  colorWarningDarker,
  colorWarningDark,
  colorWarningNormal,
  colorWarningLight,
  colorWarningLighter,
  colorWarningLightest,
  colorSurfaceSemanticDanger,
  colorSurfacePrimaryDefault,
  colorSurfacePrimaryDisabled,
  colorSurfaceSecondaryDefault,
  colorSurfaceSecondaryDisabled,
  colorSurfaceTertiaryDefault,
  colorSurfaceTertiaryDisabled,
  colorSurfaceSemanticSuccess,
  colorSurfaceSemanticInfo,
  colorSurfaceSemanticWarning,
  colorBasePrimary900,
  colorBasePrimary800,
  colorBasePrimary700,
  colorBasePrimary600,
  colorBasePrimary500,
  colorBasePrimary400,
  colorBasePrimary300,
  colorBasePrimary200,
  colorBasePrimary100,
  colorBasePrimary050,
  colorBaseGrayscale1100,
  colorBaseGrayscale1000,
  colorBaseGrayscale900,
  colorBaseGrayscale800,
  colorBaseGrayscale700,
  colorBaseGrayscale600,
  colorBaseGrayscale500,
  colorBaseGrayscale400,
  colorBaseGrayscale300,
  colorBaseGrayscale200,
  colorBaseGrayscale100,
  colorBaseGrayscale050,
  colorBaseGrayscale000,
} = variables;

export {
  colorBlue,
  colorGreen,
  colorOrange,
  colorYellow,
  colorMagenta,
  colorViolet,
  colorDarkBlue,
  colorBlack,
  colorBasePrimary,
  colorPrimary70,
  colorPrimary50,
  colorPrimary30,
  colorPrimary10,
  colorBaseSecondary,
  colorSecondary70,
  colorSecondary50,
  colorSecondary30,
  colorSecondary10,
  colorBaseDark,
  colorDark70,
  colorDark50,
  colorDark30,
  colorDark10,
  colorBaseWhite,
  colorWhite70,
  colorWhite50,
  colorWhite30,
  colorWhite10,
  colorBaseInfo,
  colorInfo30,
  colorPending30,
  colorSuccess30,
  colorError30,
  colorTextPrimary,
  colorTextSecondary,
  colorTextTertiary,
  colorTextLink,
  colorTextDestructive,
  colorTextReversed,
  colorTextButtonDefault,
  colorTextInfo,
  colorTextWarning,
  colorTextDanger,
  colorTextSuccess,
  colorButtonPrimary,
  colorButtonPrimaryHover,
  colorButtonPrimaryActive,
  colorButtonSecondary,
  colorButtonSecondaryHover,
  colorButtonSecondaryActive,
  colorButtonDisabled,
  colorStatusSuccess100,
  colorStatusSuccess60,
  colorStatusSuccess30,
  colorStatusInfo100,
  colorStatusInfo60,
  colorStatusInfo30,
  colorStatusWarning100,
  colorStatusWarning60,
  colorStatusWarning30,
  colorStatusError100,
  colorStatusError60,
  colorStatusError30,
  colorStatusPending100,
  colorStatusPending60,
  colorStatusPending30,
  colorSurfacePrimary,
  colorSurfacePrimaryHover,
  colorSurfacePrimaryActive,
  colorSurfacePrimarySelect,
  colorSurfacePrimaryDisable,
  colorSurfaceSecondary,
  colorSurfaceSecondaryHover,
  colorSurfaceSecondaryActive,
  colorSurfaceSecondarySelect,
  colorSurfaceSecondaryDisable,
  colorSurfaceTertiary,
  colorSurfaceTertiaryHover,
  colorSurfaceTertiaryActive,
  colorSurfaceTertiarySelect,
  colorSurfaceTertiaryDisable,
  colorBorder1,
  colorBorder2,
  colorBorder3,
  colorBorderDark,
  colorMainBackground,
  colorSuccessDarkest,
  colorSuccessDarker,
  colorSuccessDark,
  colorSuccess400Default,
  colorSuccessLight,
  colorSuccessLighter,
  colorSuccessLightest,
  colorInfoDarkest,
  colorInfoDark,
  colorInfoDarker,
  colorInfoNormal,
  colorInfoLight,
  colorInfoLighter,
  colorInfoLightest,
  colorDangerDarkest,
  colorDangerDarker,
  colorDangerDark,
  colorDangerNormal,
  colorDangerLight,
  colorDangerLighter,
  colorDangerLightest,
  colorWarningDarkest,
  colorWarningDarker,
  colorWarningDark,
  colorWarningNormal,
  colorWarningLight,
  colorWarningLighter,
  colorWarningLightest,
  colorSurfaceSemanticDanger,
  colorSurfacePrimaryDefault,
  colorSurfacePrimaryDisabled,
  colorSurfaceSecondaryDefault,
  colorSurfaceSecondaryDisabled,
  colorSurfaceTertiaryDefault,
  colorSurfaceTertiaryDisabled,
  colorSurfaceSemanticSuccess,
  colorSurfaceSemanticInfo,
  colorSurfaceSemanticWarning,
  colorBasePrimary900,
  colorBasePrimary800,
  colorBasePrimary700,
  colorBasePrimary600,
  colorBasePrimary500,
  colorBasePrimary400,
  colorBasePrimary300,
  colorBasePrimary200,
  colorBasePrimary100,
  colorBasePrimary050,
  colorBaseGrayscale1100,
  colorBaseGrayscale1000,
  colorBaseGrayscale900,
  colorBaseGrayscale800,
  colorBaseGrayscale700,
  colorBaseGrayscale600,
  colorBaseGrayscale500,
  colorBaseGrayscale400,
  colorBaseGrayscale300,
  colorBaseGrayscale200,
  colorBaseGrayscale100,
  colorBaseGrayscale050,
  colorBaseGrayscale000,
};
