import { Spin, SpinProps } from 'antd';
import styles from './styles.module.scss';

const Loading = (props: SpinProps): JSX.Element => (
  <div className={styles.Loading}>
    <Spin size="large" {...props} />
  </div>
);

export default Loading;
