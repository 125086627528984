import { GTGSButton, GTGSTypography } from '@/components/GTGS';
import { Flex, Result } from 'antd';
import { useCallback } from 'react';

export const ErrorPage = () => {

  const reloadPage = useCallback(() => {
    location.reload();
  }, []);

  return (
    <Result
        style={{ height: "100vh", width: "100%" ,display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}
        status="warning"
        extra={
          <Flex justify="center" vertical align="center" gap={16} style={{ height: "100%", width: "100%" }}>
            <Flex justify="center" vertical align="center" gap={4}>
              <GTGSTypography.Title level={2}>{ "Unknown Error"}</GTGSTypography.Title>
              <GTGSTypography.Title level={4} type="secondary">
                {"An unknown error occurred. Please refresh the page and try again"}
              </GTGSTypography.Title>
            </Flex>
              <GTGSButton type="primary" size="large" onClick={reloadPage}>
                {"Refresh Page"}
              </GTGSButton>
          </Flex>
        }
      />
  );
};

export default ErrorPage;