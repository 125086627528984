import { Typography as AntdTypography } from 'antd';
import styled from '@emotion/styled';
import IBText from './GTGSText';
import { memo } from 'react';
interface GTGSTypographyProps extends React.ComponentProps<typeof AntdTypography> {
  children: React.ReactNode;
}

const TypographyStyled = styled(AntdTypography.Text)<GTGSTypographyProps>``;

function GTGSTypography(props: GTGSTypographyProps) {
  return <TypographyStyled {...props} />;
}

GTGSTypography.Text = IBText;
GTGSTypography.Title = AntdTypography.Title;
GTGSTypography.Paragraph = AntdTypography.Paragraph;

export { GTGSTypography, type GTGSTypographyProps};
