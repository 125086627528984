import { Typography } from 'antd';
import styled from '@emotion/styled';
import colors from '@/theme/colors.module.scss';
import omit from 'lodash/omit';
interface TextProps
  extends Omit<React.ComponentProps<typeof Typography.Text>, 'type'> {
  children: React.ReactNode;
  size?: '2xs' | 'xs' | 'sm' | 'base';
  status?:
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'info'
    | 'default'
    | 'tertiary'
    | 'destructive';
  textAlignment?: 'left' | 'center' | 'right';
}

const TextStyled = styled((props: TextProps) => {
  return <Typography.Text {...omit(props, ['textAlignment'])} />;
})<TextProps>`
  font-size: ${({ size }) => {
    switch (size) {
      case '2xs':
        return '10px';
      case 'xs':
        return '12px';
      case 'sm':
        return '14px';
      case 'base':
        return '16px';
      default:
        return '16px';
    }
  }};
  line-height: ${({ size, strong }) => {
    switch (size) {
      case '2xs':
        return '16px';
      case 'xs':
        return '16px';
      case 'sm':
        return strong ? '22px' : '20px';
      case 'base':
        return '24px';
      default:
        return '24px';
    }
  }};
  color: ${({ status }) => {
    switch (status) {
      case 'secondary':
        return colors.colorTextSecondary;
      case 'success':
        return colors.colorTextSuccess;
      case 'warning':
        return colors.colorTextWarning;
      case 'danger':
        return colors.colorTextDanger;
      case 'info':
        return colors.colorTextInfo;
      case 'tertiary':
        return colors.colorTextTertiary;
      case 'destructive':
        return colors.colorTextDanger;
      default:
        return colors.colorTextPrimary;
    }
  }};
  text-align: ${({ textAlignment }) => textAlignment};
`;

//==================  MAIN =================

function GTGSText(props: TextProps) {
  const { children, size = 'base', status, ...rest } = props;
  return (
    <TextStyled size={size} status={status} {...rest}>
      {children}
    </TextStyled>
  );
}

GTGSText.Title = Typography.Title;

export default GTGSText;
