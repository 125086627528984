import axios from 'axios';
import authService from './authService';
import UserService from './UserService';
import { uuidv4 } from '@/utils/commonUtils';
import omit from 'lodash/omit';
import { ENotifyErrorType } from '@/types/logError';
import { trans } from '@/i18n';
import { NOTIFY_ERROR_TYPE } from '@/utils/handleError';
import { notification } from 'antd';
const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

const axiosInstance = axios.create();

const updateAccessToken = (token: string) => {
  axiosInstance.defaults.headers.common.Authorization = token
    ? `Bearer ${token}`
    : '';
};

const getHttpInstance = () => axiosInstance;

const configDeviceToken = (deviceId) => {
  axiosInstance.interceptors.request.use((config) => {
    if (deviceId && deviceId !== 'undefined') {
      // Add device_token to the URL parameters only if it doesn't already exist
      // const url = new URL(config?.url ?? '');
      // if (!url.searchParams.has('currentDeviceId')) {
      //   url.searchParams.append('currentDeviceId', deviceId);
      //   config.url = url.toString();
      // }
      // Add deviceId to the request headers
      config.headers['x-device-id'] = deviceId;
    }
    return config;
  });
};

type ObjectAny<T> = T & { [key: string]: any };

const _handleError = (_, notifyErrorType) => {
  switch (notifyErrorType) {
    case ENotifyErrorType.PUSH_MESSAGE: {
      setTimeout(() => {
        notification?.destroy();
        notification.error({
          message: trans('common.callAPIError'),
        });
      }, 500);
      break;
    }
    case ENotifyErrorType.PUSH_UNAVAILABLE: {
      // TODO
      break;
    }
    case ENotifyErrorType.NONE: {
      // do nothing
      break;
    }
    // default push message error
    default:
      setTimeout(() => {
        notification?.destroy();
        notification.error({
          message: trans('common.callAPIError'),
        });
      }, 500);
  }
};
const _onServerError = (error: {
  [key: string]: any;
  config: ObjectAny<{
    originalRequestData: {
      [k: string]: any;
      [NOTIFY_ERROR_TYPE]?: ENotifyErrorType;
    };
  }>;
}) => {
  const urlPagesIgnoreError = ['/', ''];
  if (
    (error?.code === 'ERR_NETWORK' || error.response?.status >= 500) &&
    !urlPagesIgnoreError.includes(window.location.pathname)
  ) {
    const { config } = error || {};
    const { originalRequestData } = config || {};
    _handleError(error, originalRequestData?.[NOTIFY_ERROR_TYPE]);
    return Promise.reject();
  }
  return Promise.reject(error);
};

const configure = () => {
  if (authService.isAuthenticated()) {
    updateAccessToken(authService.getAccessToken() || '');
  }
  // Trace request by id from client
  axiosInstance.defaults.headers.common['x-request-id'] = uuidv4();
  axiosInstance.defaults.headers.common['channel-name'] = 'web';
  axiosInstance.defaults.headers.common['x-app-version'] = '1.0';
  axiosInstance.defaults.headers.common['x-channel-name'] = 'web';

  axiosInstance.interceptors.request.use((config) => {
    const currentDeviceId = localStorage.getItem('deviceId');
    if (currentDeviceId && currentDeviceId !== 'undefined') {
      // Add device_token to the URL parameters only if it doesn't already exist
      const url = new URL(config?.url ?? '');
      if (!url.searchParams.has('currentDeviceId')) {
        url.searchParams.append('currentDeviceId', currentDeviceId);
        config.url = url.toString();
      }
    }
    return config;
  });

  // interceptor for notify
  axiosInstance.interceptors.request.use((_config) => {
    /// remove _logType from the params.
    (_config as any).originalRequestData = {
      ..._config.params,
      ..._config.data,
    };
    _config.params = omit(_config.params, [NOTIFY_ERROR_TYPE]);
    _config.data = omit(_config.data, [NOTIFY_ERROR_TYPE]);
    return _config;
  });
  // Force log out if unauthorized
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { success, data } = error?.response?.data ?? {};
      if (error.response?.status === 401) {
        if (!data?.confirmationId && !success) {
          localStorage.setItem('isLoggedIn', 'false');
          UserService.doLogout(window.location.href);
        }
      }
      if (error.response?.status === 403) {
        // window.location.reload(); //TODO: try refresh token
      }
      // handle error server response
      return _onServerError(error);
    }
  );
};
// device_token
const httpService = {
  HttpMethods,
  updateAccessToken,
  configure,
  getHttpInstance,
  get: getHttpInstance().get,
  post: getHttpInstance().post,
  put: getHttpInstance().put,
  patch: getHttpInstance().patch,
  delete: getHttpInstance().delete,
  isAxiosError: axios.isAxiosError,
  configDeviceToken,
};

export default httpService;
