import { GTGSButton, GTGSTypography, GTGSIconN,  } from '@/components/GTGS';
import HamburgerIcon from '@/components/GTGS/GTGSIcon/icons/HamburgerIcon';
import { useResponsive } from '@/hooks';
import { Drawer, Flex, Image, Layout } from 'antd';
import { memo, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Menu from '../Menu/Menu';
import { FlagSlider } from './components/FlagSlider';
import styles from './styles.module.scss';

const { Sider } = Layout;

export const SIDER_WIDTH = 240;
export const MENU_DESKTOP_WIDTH = 216;

function _LeftSlider() {
  const [collapsed, setCollapsed] = useState(false);
  const { md, xs } = useResponsive();
  const { t } = useTranslation();

  const triggerButton = useMemo(() => {
    const renderPlace = document.getElementById('btnTriggerMenu');
    if (renderPlace && !md) {
      return createPortal(
        <>
          <GTGSButton
            style={{
              height: 24,
              padding: 0,
              paddingRight: 16,
            }}
            type="text"
            onClick={() => setCollapsed(!collapsed)}
            iconEnd={<HamburgerIcon />}
          />
          <Link to="/">
            {/* <img name="ibk" size={{ width: xs ? 80 : 110, height: 78 }} /> */}
            LOGO
          </Link>
        </>,
        renderPlace
      );
    }
    return null;
  }, [collapsed, md, xs]);

  if (!md) {
    return (
      <>
        {triggerButton}
        <Drawer
          className={styles.customDrawer}
          extra={
            <Link to="/">
              {/* <
                name="ibk"
                size={{ width: xs ? 80 : 110, height: 52 }}
              /> */}
              LOGO
            </Link>
          }
          onClose={() => setCollapsed(false)}
          width={SIDER_WIDTH}
          open={collapsed}
          placement="left"
          destroyOnClose={false}
          styles={{
            header: {
              padding: '12px 24px',
            },
            body: {
              padding: 0,
            },
          }}
        >
          <Flex vertical className={styles.container}>
            <Flex
              className={styles.sidebar}
              vertical
              justify="space-between"
            >
              <Menu
                setCollapsed={setCollapsed}
                style={{ width: MENU_DESKTOP_WIDTH, marginTop: 16 }}
              />{' '}
              <Flex className={styles.footer} vertical>
                <FlagSlider />
                <div className={styles.supportItem}>
                  <Flex gap={16} align="center" justify="flex-center">
                    <GTGSIconN name="question-mark" size={24} />
                    <GTGSTypography.Text size="sm">
                      {t('common.menu.support')}
                    </GTGSTypography.Text>
                  </Flex>
                </div>
              </Flex>
            </Flex>
          </Flex>
        </Drawer>
      </>
    );
  }

  return (
    <Sider
      width={SIDER_WIDTH}
      collapsible
      collapsedWidth={0}
      collapsed={collapsed}
      trigger={null}
      style={{
        height: '100vh',
        left: 0,
        top: 0,
        bottom: 0,
        background: '#fff',
        position: 'fixed',
      }}
    >
      <Flex vertical className={styles.container}>
          
        <Flex
          align="center"
          style={{ marginLeft: 32, height: 78 }}
          justify="flex-start"
        >
          <Link to="/">
            <Image
              preview={false}
              height={32}
              width={'auto'}
              src="/images/logo/logo.svg"
              alt="logo"
            />
          </Link>
        </Flex>
        <Flex className={styles.sidebar} vertical justify="space-between">
          <Menu
            style={{ width: MENU_DESKTOP_WIDTH, paddingTop: 12, margin: 0 }}
          />
          
          <Flex className={styles.footer} vertical>
            <FlagSlider />
          </Flex>
        </Flex>
      </Flex>
    </Sider>
  );
}

export const LeftSlider = memo(_LeftSlider);
