import styled from '@emotion/styled';
import { Input as AntdInput, InputProps as AntdInputProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { GTGSTypography } from '../GTGSTypography/GTGSTypography';
import { GTGSInputAmount } from './GTGSInputAmount';
import { GTGSTextArea } from './GTGSInputArea';
import { GTGSInputCurrency } from './GTGSInputCurrency';

interface GTGSInputProps extends AntdInputProps {}

const InputStyled = styled(AntdInput)<GTGSInputProps>`
  .ant-input-show-count-suffix {
    position: absolute;
    right: 0;
    top: -26px;
  }
`;

const GTGSInput = ({ children, showCount, ...rest }: GTGSInputProps) => {
  const { t } = useTranslation();
  const _showCount = showCount
    ? {
        formatter: (_formatter) => (
          <GTGSTypography.Text size="sm" status="secondary">
            {t('common.maxCharacters', {
              number: `${_formatter.count}/${_formatter.maxLength}`,
            })}
          </GTGSTypography.Text>
        ),
      }
    : false;

  return (
    <InputStyled showCount={_showCount} {...rest}>
      {children}
    </InputStyled>
  );
};

GTGSInput.Group = AntdInput.Group;
GTGSInput.Search = AntdInput.Search;
GTGSInput.Password = AntdInput.Password;
GTGSInput.OTP = AntdInput.OTP;
GTGSInput.Amount = GTGSInputAmount;
GTGSInput.Currency = GTGSInputCurrency;
GTGSInput.TextArea = GTGSTextArea;

export { GTGSInput, type GTGSInputProps };

