import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouterGuideline } from '../../routerConfig';
import styles from './Sider.module.scss';

const { Sider } = Layout;

const items: MenuProps['items'] = RouterGuideline.map((item) => ({
  key: item.path,
  label: item.title,
}));

const SiderLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [current, setCurrent] = useState('/guideline/button');

  useEffect(() => {
    if (location.pathname) {
      setCurrent(location.pathname);
    }
  }, [location.pathname]);

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
    navigate(e.key);
  };
  return (
    <Sider
      style={{
        // overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <Menu
        theme="dark"
        items={items}
        onClick={onClick}
        selectedKeys={[current]}
        mode="vertical"
        className={styles.menu}
      />
    </Sider>
  );
};

export default SiderLayout;
