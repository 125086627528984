import { Divider, Flex, InputProps, Space, Typography } from 'antd';
import styles from './styles.module.scss';
import { memo } from 'react';
import { GTGSInput } from './GTGSInput';

interface GTGSInputCurrencyProps extends InputProps {
  currencyPosition?: 'start' | 'end';
  currency?: 'USD' | 'KHR';
}

const _GTGSInputCurrency = ({
  currency,
  currencyPosition = 'start',
  ...rest
}: GTGSInputCurrencyProps) => {
  return (
    <Space.Compact className={styles.inputWithSelect}>
      <GTGSInput
        variant="borderless"
        {...rest}
        style={{ order: currencyPosition === 'end' ? 0 : 2 }}
      />
      <Divider
        type="vertical"
        style={{
          height: '24px',
          width: '2px',
          margin: 'auto',
          borderInlineWidth: 2,
          order: 1,
        }}
      />
      <Flex
        align="center"
        justify="center"
        style={{ width: 60, order: currencyPosition === 'end' ? 2 : 0 }}
      >
        <Typography.Text>{currency}</Typography.Text>
      </Flex>
    </Space.Compact>
  );
};

const GTGSInputCurrency = memo(_GTGSInputCurrency);

export { GTGSInputCurrency, type GTGSInputCurrencyProps };
