import { Tag, TagProps } from 'antd';
import styled from '@emotion/styled';
import colors from '@/theme/colors.module.scss';
import { memo } from 'react';

interface GTGSChipProps extends TagProps {
  state?: 'active' | 'hover' | 'default';
  size?: 'large' | 'small';
}

const GTGSChipStyled = styled(Tag)<GTGSChipProps>`
  border-radius: 120px;
  color: $color-base-darker-500;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  border: 1px solid $color-border-normal;
  justify-content: space-between;
  gap: 8px;
  background: ${colors.colorBaseLighter000};
  word-wrap: break-word;

  &:hover {
    background: ${colors.colorBasePrimary500};
    color: ${colors.colorBaseLighter000};
    border: 1px transparent solid !important;
  }

  ${({ size }) => {
    switch (size) {
      case 'large':
        return `
          height: 36px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;   
        `;
      case 'small':
        return `height: 24px;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        `;
    }
  }};
`;

function _GTGSChip(props: GTGSChipProps) {
  const { children, state = 'default', size = 'large', ...rest } = props;

  return (
    <GTGSChipStyled state={state} size={size} {...rest}>
      {children}
    </GTGSChipStyled>
  );
}

const GTGSChip = memo(_GTGSChip);

export { GTGSChip, type GTGSChipProps };
