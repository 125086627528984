import { useResponsive } from '@/hooks';
import { ConfigProvider, Flex, Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GTGSIconN } from '../GTGS';
import { searchFeatures } from './data';
import DesktopSearch from './DesktopSearch';
import MobileSearch from './MobileSearch';
import { GTGSTypography } from '../GTGS/GTGSTypography/GTGSTypography';

const GlobalSearch = () => {
  const { t } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState(false);
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { sm } = useResponsive();
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            borderRadius: 50,
          },
        },
      }}
    >
      <Flex align="center">
        {sm ? <DesktopSearch /> : <MobileSearch />}
      </Flex>
    </ConfigProvider>
  );
};

export default React.memo(GlobalSearch);
