import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as WalletSvg } from '../svg/Wallet.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const WalletIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={WalletSvg} {...props} />;
};

export default WalletIcon;
