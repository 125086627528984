import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as SignOutSvg } from '../svg/SignOut.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const SignOutIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={SignOutSvg} {...props} />;
};

export default SignOutIcon;
