import { Loading } from '@/components';
import loadable from '@loadable/component';
import { routesPath } from './constants';

const ChannelsPage = loadable(() => import('@/pages/Channels'), {
  fallback: <Loading />,
});

// --------------------------------------------------------------------------- ROUTER
const RouterChannels = {
  path: routesPath.CHANNELS,
  children: [
    {
      path: routesPath.CHANNELS,
      element: <ChannelsPage />,
    },
  ],
};

export default RouterChannels;
