// theme/themeConfig.ts
import type { ThemeConfig } from 'antd';
import colors from './colors.module.scss';

const theme: ThemeConfig = {
  token: {
    fontSize: 14,
    fontFamily: 'SF Pro Display, -apple-system, Lato, Open Sans, Arial',
    colorPrimary: colors.colorBasePrimary500,
    colorText: colors.colorTextPrimary,
    colorTextSecondary: colors.colorBaseSecondary500,
    colorErrorBorderHover: colors.colorDangerNormal,
    colorError: colors.colorDangerNormal,
    colorWhite: colors.colorBaseLighter000,
  },
  components: {
    Button: {
      borderRadius: 500,
      borderRadiusSM: 500,
      borderRadiusXS: 500,
      borderRadiusLG: 500,
      fontWeight: 600,
      contentFontSize: 14,
      defaultShadow: 'none',
      primaryShadow: 'none',
      dangerShadow: 'none',

      colorText: colors.colorTextPrimary,
      colorBgBase: colors.colorButtonPrimarySurface,
      colorBorder: colors.colorButtonSecondaryBorder,

      colorBgContainerDisabled: colors.colorButtonSecondarySurfaceDisabled,
      borderColorDisabled: colors.colorButtonSecondarySurfaceDisabled,

      colorTextSecondary: colors.colorButtonSecondaryText,

      colorInfo: colors.colorButtonTextLinkText,

      // Primary
      colorPrimary: colors.colorButtonPrimarySurface,
      primaryColor: colors.colorTextReversed,
      colorPrimaryHover: colors.colorButtonPrimarySurfaceHover,
      colorPrimaryBgHover: colors.colorButtonPrimarySurfaceHover,
      colorPrimaryActive: colors.colorButtonPrimarySurfaceActive,

      // Secondary - (Ant Design default)
      defaultHoverBg: colors.colorButtonSecondarySurfaceHover,
      defaultActiveBg: colors.colorButtonSecondarySurfaceActive,
      defaultHoverBorderColor: colors.colorButtonSecondaryBorder,
      defaultActiveColor: colors.colorButtonSecondaryText,
      defaultColor: colors.colorButtonSecondaryText,
      defaultHoverColor: colors.colorButtonSecondaryText,
      defaultActiveBorderColor: colors.colorButtonSecondaryBorder,

      // Link
      colorLink: colors.colorButtonTextLinkText,
      colorLinkHover: colors.colorButtonTextLinkTextHover,
      textHoverBg: 'none',

      // Error
      colorError: colors.colorButtonDangerText,

      // Height control
      controlHeight: 40,
      controlHeightLG: 48,
      controlHeightSM: 32,
      // Line height
      contentLineHeight: 1.25,
      contentLineHeightSM: 1.25,
      //
      paddingInlineSM: 12,
    },
    Pagination: {
      itemActiveBg: colors.colorBaseLighter030,
      colorPrimaryBorder: colors.colorBasePrimary050,
      colorPrimary: colors.colorTextPrimary,
      colorPrimaryHover: colors.colorTextPrimary,
      colorBorder: colors.colorBasePrimary050,
    },
    Tabs: {
      colorPrimary: colors.colorBaseSecondary500,
      itemColor: colors.colorTextSecondary,
      horizontalItemPadding: '8px 24px',
      itemSelectedColor: colors.colorTextPrimary,
      inkBarColor: colors.colorBasePrimary400,
      horizontalItemGutter: 0,
      itemHoverColor: colors.colorTextPrimary,
      itemActiveColor: colors.colorTextPrimary,
      titleFontSize: 14,
      marginSM: 6,
      lineHeight: 1.42857142857,
      // Tab Card
      horizontalMargin: '0px',
      lineWidth: 0,
      lineWidthBold: 0,
      lineWidthFocus: 0,
      cardGutter: 8,
      borderRadiusLG: 12,
      cardBg: colors.colorBaseLighter040,
      cardPadding: '12px 16px',
    },
    Typography: {
      titleMarginBottom: 0,
      fontSizeHeading1: 34,
      lineHeightHeading1: 3,
      fontSizeHeading2: 24,
      lineHeightHeading2: 1.33,
      fontSizeHeading3: 20,
      lineHeightHeading3: 1.5,
      fontSizeHeading4: 18,
      lineHeightHeading4: 1.33333333333,
      colorTextHeading: '#1C1C48',
      //
      titleMarginTop: 0,
      colorLink: colors.colorBasePrimary500,
    },

    Timeline: {
      dotBg: 'transparent',
    },
    Table: {
      cellFontSize: 14,
      cellPaddingBlock: 10.1,
      cellPaddingInline: 12,
      footerBg: 'transparent',
      headerBorderRadius: 12,
    },
    Card: {
      paddingLG: 24,
      padding: 16,
      // border radius
      borderRadius: 12,
      borderRadiusLG: 12,
      // box shadow
      boxShadow: 'none',
      boxShadowSecondary: 'none',
      boxShadowTertiary: 'none',
    },
    Tag: {
      defaultBg: colors.colorBasePrimary100,
      defaultColor: colors.colorTextPrimary,
    },
    Input: {
      fontSizeLG: 16,
      fontSize: 16,
      controlHeight: 40,
      // paddingBlock: 12,
      paddingInline: 16,
      // paddingInlineLG: 16,
      borderRadius: 8,
      colorBorder: colors.colorBorderLight,
      colorBgContainerDisabled: '#F1F3F5',
      controlItemBgActiveDisabled: colors.colorTextPrimary,
    },
    Select: {
      optionSelectedBg: colors.colorBasePrimary050,
      fontSizeLG: 16,
      borderRadiusLG: 8,
      borderRadius: 8,
      colorBorder: colors.colorBorderLight,
      paddingSM: 16,
      colorBgContainerDisabled: '#F1F3F5',
      colorTextDisabled: colors.colorTextPrimary,
      colorText: colors.colorTextPrimary,
      colorPrimary: colors.colorPrimary,
      controlOutline: colors.colorPrimary,
      colorTextPlaceholder: colors.colorTextTertiary,
    
    },
    Form: {
      labelFontSize: 16,
      verticalLabelPadding: 2,
    },
    Steps: {
      fontSize: 14,
    },
    Skeleton: {
      // colorFillContent: colors.colorSurfaceTertiaryDefault,
    },
    Dropdown: {
      controlItemBgHover: colors.colorSurfacePrimaryHover,
    },
    Badge: {
      fontSizeSM: 10,
      colorError: colors.colorBaseSecondary500,
      colorBorderBg: 'transparent',
    },
    Switch: {
      colorPrimary: colors.colorBasePrimary500,
      trackMinWidth: 36
    },
    Layout: {
      bodyBg: colors.colorBaseLighter000,
      siderBg: colors.colorBaseLighter000,
      headerHeight: 56,
    },
    Menu: {
      fontSize: 14,
      itemColor: colors.colorTextPrimary,
      itemSelectedBg: colors.colorBasePrimary500,
      itemBorderRadius: 1000,

      subMenuItemBg: '#F1F3F5',
      subMenuItemBorderRadius: 12,
      horizontalItemBorderRadius: 12,
      borderRadius: 12,

      itemHeight: 48,
      lineHeight: 48,
      // Active
      itemSelectedColor: colors.colorBaseLighter000,
      itemActiveBg: '#F1F2F5',
      activeBarBorderWidth: 0,
      // Hover
      itemHoverBg: '#F1F2F5',
      colorIconHover: '#5E5E7D',
      itemHoverColor: '#5E5E7D',
    },
    Segmented: {
      colorText: colors.colorTextPrimary,
      colorTextLabel: colors.colorTextPrimary,
      borderRadius: 8,
      borderRadiusSM: 6,
      borderRadiusLG: 8,
      fontSize: 14,
      trackPadding: 4,
      controlHeight: 37,
      trackBg: '#F1F3F5',
      fontWeightStrong: 600,
    },
    InputNumber: {
      fontSize: 16,
      fontSizeLG: 16,
      controlHeight: 40,
      paddingInlineLG: 16,
    },
    Breadcrumb: {
      itemColor: colors.colorTextSecondary,
      lastItemColor: colors.colorTextPrimary,
      separatorColor: colors.colorTextPrimary,
      colorBgTextHover: 'transparent',
      linkColor: colors.colorTextSecondary,
    },
    Alert: {
      marginXS: 8,
      marginSM: 8,
      lineWidth: 0,
      borderRadiusLG: 16,
      withDescriptionPadding: 16,
      defaultPadding: 16,
      fontSizeLG: 16,
      // Color
      colorSuccessBg: colors.colorSuccessLightest,
      colorInfoBg: colors.colorSurfaceSemanticInfo,
      colorWarningBg: colors.colorWarningLightest,
      colorErrorBg: colors.colorSurfaceSemanticDanger,
    },
    DatePicker: {
      borderRadius: 8,
      fontSizeLG: 16,
    },
    Divider: {
      verticalMarginInline: 0,
      margin: 8,
      marginLG: 8,
    },
    Empty: {
      colorText: colors.colorTextTertiary,
      fontSize: 14,
      controlHeight: 160,
      controlHeightLG: 64,
      lineHeight: 1.42857142857,
    },
    Modal: {
      borderRadiusLG: 16,
      paddingContentVertical: 24,
      paddingContentHorizontal: 24,
      padding: 24,
    },
  },
  cssVar: true,
};

export default theme;
