import { Loading } from '@/components';
import loadable from '@loadable/component';
import { routesPath } from './constants';

const CasesPage = loadable(() => import('@/pages/Cases'), {
  fallback: <Loading />,
});

// --------------------------------------------------------------------------- ROUTER
const RouterAutomations = {
  path: routesPath.AUTOMATIONS,
  children: [
    {
      path: routesPath.AUTOMATIONS,
      element: <CasesPage />,
    },
  ],
};

export default RouterAutomations;
