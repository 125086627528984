import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as WarningDiamondSvg } from '../svg/WarningDiamond.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const WarningDiamondIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={WarningDiamondSvg} {...props} />;
};

export default WarningDiamondIcon;
