import config from '@/config';
// import { getFirebaseToken } from '@/helpers/firebase';
import Keycloak from 'keycloak-js';
import httpService from './httpService';

const initOptions = {
  url: config.keycloak.ENDPOINT,
  realm: config.keycloak.REALM,
  clientId: config.keycloak.CLIENTID,
  KeycloakResponseType: 'code',
};

console.log({ initOptions });
const _kc = new Keycloak(initOptions);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (
  onBeforeAuthenticated: () => void,
  onAuthenticatedCallback: () => void
) => {
  onBeforeAuthenticated();
  console.log(_kc);
  _kc
    .init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    })
    .then((authenticated) => {
      console.log({ authenticated });
      if (!authenticated) {
        localStorage.setItem('isLoggedIn', 'false');

        return doLogin();
      } else {
        httpService.configure();
        localStorage.setItem('isLoggedIn', 'true');
        // if (
        //   'Notification' in window &&
        //   window.Notification?.permission === 'granted'
        // ) {
        //   Notification.requestPermission().then((permission) => {
        //     console.log('permission :>> ', permission);
        //   });
        // } else {
        onAuthenticatedCallback();
        // }
      }
    })
    .catch((error) => {
      console.error('Keycloak init error', error);
    });
};

_kc.onAuthSuccess = function () {
  const tokenParsed = _kc.tokenParsed;
  if (tokenParsed?.exp) {
    const currentTime = Math.floor(Date.now() / 1000);
    const intervalTimeUpdateToken =
      Math.floor(tokenParsed?.exp - currentTime - 30) * 1000;

    const updateTokenInterval = setInterval(() => {
      _kc
        .updateToken(60)
        .then(() => {
          const token = _kc.token;
          if (token) {
            httpService.updateAccessToken(token);
          }
        })
        .catch(() => {
          _kc.clearToken();
          clearInterval(updateTokenInterval);
          localStorage.removeItem('deviceId');
          localStorage.removeItem('appOpened');
          doLogout(window.location.href);
        });
    }, intervalTimeUpdateToken);
  }
};

const doLogin = _kc.login;

const doLogout = (redirectUri?: string) => {
  localStorage.removeItem('deviceId');
  localStorage.removeItem('appOpened');
  _kc.logout({ redirectUri: redirectUri || window.location.origin });
};

const doClearToken = () => _kc.clearToken();

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const getProfile = () => _kc.loadUserProfile;

const getUserInfo = () => ({
  id: _kc.tokenParsed?.sub,
  userName: _kc.tokenParsed?.preferred_username,
  name: _kc.tokenParsed?.name,
  firstName: _kc.tokenParsed?.family_name,
  lastName: _kc.tokenParsed?.given_name,
  email: _kc.tokenParsed?.email,
  customerId: _kc.tokenParsed?.customerId,
});

const updateToken = (
  successCallback:
    | ((value: boolean) => boolean | PromiseLike<boolean>)
    | null
    | undefined
) => _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));
const isTokenExpired = () => _kc.isTokenExpired();

const getUserId = () => _kc?.subject;

enum ESignature {
  TXN_SIGNATURE_BRONZE = 'txn-signature-bronze',
  TXN_SIGNATURE_SILVER = 'txn-signature-silver',
  TXN_SIGNATURE_GOLD = 'txn-signature-gold',
  TXN_SIGNATURE_DIAMOND = 'txn-signature-diamond',
  TXN_SIGNATURE_TITANIUM = 'txn-signature-titanium',
}

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  hasRole,
  getProfile,
  getUserInfo,
  doClearToken,
  isTokenExpired,
  ESignature,
  getUserId,
};

export default UserService;
