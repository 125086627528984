import { Loading } from '@/components';
import loadable from '@loadable/component';
import { routesPath } from './constants';

const WorkspacePage = loadable(() => import('@/pages/Cases'), {
  fallback: <Loading />,
});

// --------------------------------------------------------------------------- ROUTER
const RouterWorkspace = {
  path: routesPath.WORKSPACE,
  element: <WorkspacePage />,
};

export default RouterWorkspace;
