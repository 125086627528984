/* eslint-disable global-require */
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeekday from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import { SHORT_DATE_FORMAT } from '@/constants/date-time';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);
dayjs.extend(isoWeekday);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

type date = string | number | Date;

let defaultTimeAgoPrefix = 'on ';
let defaultTimeAgoAt = 'at';

export function setDayjsLocale(
  t: ReturnType<typeof useTranslation>[0],
  locale = 'en'
) {
  if (locale === 'vi') require('dayjs/locale/vi');
  if (locale === 'ko') require('dayjs/locale/ko');
  if (locale === 'ja') require('dayjs/locale/ja');
  if (locale === 'zh') require('dayjs/locale/zh');
  if (locale === 'en') require('dayjs/locale/en');

  dayjs?.locale(locale);

  defaultTimeAgoPrefix = t('time-prefix', { ns: 'common' });
  defaultTimeAgoAt = t('time-at', { ns: 'common' });
}

export function isFuture(date: date) {
  return dayjs(date).diff(dayjs()) > 0;
}

export function isPast(date: date) {
  return dayjs(date).diff(dayjs()) < 0;
}

export function formatLongDate(date: date, at?: string) {
  return dayjs(date).format(`MMM D, YYYY [${at ?? defaultTimeAgoAt}] hh:mm A`);
}

export function formatLongAtDate(
  date: date,
  formatDate = 'DD/MM/YYYY',
  at?: string
) {
  return dayjs
    .utc(date)
    .local()
    .format(`${formatDate} [${at ?? defaultTimeAgoAt}] hh:mm A`);
}

export function formatFullDate(date: date, at?: string) {
  return dayjs(date).format(
    `${SHORT_DATE_FORMAT} [${at ?? defaultTimeAgoAt}] hh:mm A`
  );
}

export function formatDate(date: date, format: string) {
  return dayjs(date).format(format);
}

export function initDate(date: date, format: string) {
  dayjs.extend(customParseFormat);
  return dayjs(date, format);
}

export function timeago(date: date, prefix?: string, at?: string) {
  const _date = dayjs(date);
  const today = dayjs();
  if (_date.diff(today, 'day') >= -7) {
    return `${prefix ?? defaultTimeAgoPrefix}${_date.from(today)}`;
  }

  return `${prefix ?? ''}${_date.format(
    `MMM D, YYYY [${at ?? defaultTimeAgoAt}] hh:mm A`
  )}`;
}

export function timeToNow(date: date) {
  dayjs.extend(updateLocale);

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      M: '%d days',
      MM: '%d months',
      y: 'a year',
      yy: '%d years',
    },
  });

  const _date = dayjs(date);
  return dayjs(_date).toNow(true);
}

export default dayjs;
