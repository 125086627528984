import Userinfo from '@/components/UserInfo';
import { Flex, theme } from 'antd';
import { ReactNode, memo } from 'react';

import { Header } from 'antd/es/layout/layout';
import GlobalSearch from '@/components/GlobalSearch';

interface GTGSPageHeaderProps {
  hasRight?: boolean;
  className?: string;
  children?: ReactNode;
}
// ===========================================================================
// MAIN
// ===========================================================================
function _GTGSPageHeader(props: GTGSPageHeaderProps) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // --------------------------------------------------------------------------- render
  return (
    <>
      <Header
        style={{
          padding: '0 24px',
          background: colorBgContainer,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Flex flex={1} align="center">
          <Flex align="center">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              id="btnTriggerMenu"
            >
              <GlobalSearch/>
              
            </div>
          </Flex>
          <Flex
            justify="flex-end"
            flex={1}
            align="center"
            style={{ height: '100%' }}
          >
            <Userinfo />
          </Flex>
        </Flex>
      </Header>
    </>
  );
}

export const GTGSPageHeader = memo(_GTGSPageHeader);
