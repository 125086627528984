import { Loading } from '@/components';
import loadable from '@loadable/component';
import { routesPath } from './constants';

const CRMPage = loadable(() => import('@/pages/CRM'), {
  fallback: <Loading />,
});

// --------------------------------------------------------------------------- ROUTER
const RouterCRM = {
  path: routesPath.CRM,
  children: [
    {
      path: routesPath.CRM,
      element: <CRMPage />,
    },
  ],
};

export default RouterCRM;
