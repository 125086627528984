import isEqual from 'lodash/isEqual';

export const generateId = (type: string, id: string | number): string =>
  `${type}_${id}`;

export const toTitleCase = (str: string): string =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  );

export const getOrdinal = (sequence: number): string => {
  const suffixes = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
  switch (sequence % 100) {
    case 11:
    case 12:
    case 13:
      return `${sequence}th`;
    default:
      return sequence + suffixes[sequence % 10];
  }
};

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const diffObj = (
  beforeObj: any,
  afterObj: any,
  keyUpdateInfo?: string
): Array<{ updateInfo: string; valueBefore: any; valueAfter: any }> => {
  const result: Array<{
    updateInfo: string;
    valueBefore: any;
    valueAfter: any;
  }> = [];
  for (const key in beforeObj) {
    if (!isEqual(beforeObj[key], afterObj[key])) {
      result.push({
        updateInfo: keyUpdateInfo ? `${keyUpdateInfo}.${key}` : key,
        valueBefore: beforeObj[key] || '',
        valueAfter: afterObj[key] || '',
      });
    }
  }
  return result;
};

export const diffArray = (
  beforeArr: any,
  afterArr: any
): Array<{ updateInfo: string; valueBefore: any; valueAfter: any }> => {
  if (!Array.isArray(beforeArr) || !Array.isArray(afterArr)) return [];

  const result: Array<{
    updateInfo: string;
    valueBefore: any;
    valueAfter: any;
  }> = [];

  const arrTemp = beforeArr?.length > afterArr?.length ? beforeArr : afterArr;

  for (let index = 0; index < arrTemp?.length; index++) {
    const element = beforeArr?.[index] || {};
    const element2 = afterArr?.[index] || {};

    if (element?.type === element2?.type) {
      if (!isEqual(element, element2)) {
        result.push({
          updateInfo: element?.type || element2?.type || element?.ty,
          valueBefore: element,
          valueAfter: element2,
        });
      }
    } else {
      if (afterArr?.length > beforeArr?.length) {
        if (element?.type) {
          for (let index1 = 0; index1 < afterArr?.length; index1++) {
            if (afterArr?.[index1]?.type === element?.type) {
              if (!isEqual(element, afterArr?.[index1])) {
                result.push({
                  updateInfo: element?.type || afterArr?.[index1]?.type,
                  valueBefore: element,
                  valueAfter: afterArr?.[index1],
                });
              }
            }
          }
        } else {
          result.push({
            updateInfo: afterArr?.[index]?.type,
            valueBefore: {},
            valueAfter: afterArr?.[index],
          });
        }
      } else {
        if (!element2?.type) {
          for (let index2 = 0; index2 < beforeArr?.length; index2++) {
            if (beforeArr?.[index2]?.type === element2?.type) {
              if (!isEqual(element2, beforeArr?.[index2])) {
                result.push({
                  updateInfo: element2?.type || beforeArr?.[index2]?.type,
                  valueBefore: element2,
                  valueAfter: beforeArr?.[index2],
                });
              }
            }
          }
        } else {
          result.push({
            updateInfo: beforeArr?.[index]?.type,
            valueBefore: beforeArr?.[index],
            valueAfter: {},
          });
        }
      }
    }
  }

  return result;
};
