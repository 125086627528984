import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import config from '../config';

const firebaseConfig = {
  apiKey: "config.firebase_notification.API_KEY",
  authDomain: "config.firebase_notification.AUTH_DOMAIN",
  projectId: "config.firebase_notification.PROJECT_ID",
  storageBucket: "config.firebase_notification.STORAGE_BUCKET",
  messagingSenderId: "config.firebase_notification.MESSAGING_SENDER_ID",
  appId: "config.firebase_notification.APP_ID",
  measurementId: "config.firebase_notification.MEASUREMENT_ID",
};

const firebaseApp = initializeApp(firebaseConfig, firebaseConfig.projectId);

export const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    console.log('Firebase is not supported in this browser');
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

export const getOrRegisterServiceWorker = () => {
  if (
    'serviceWorker' in navigator &&
    typeof window.navigator.serviceWorker !== 'undefined'
  ) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then((serviceWorker) => {
        if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
          {
            scope: '/firebase-push-notification-scope',
          }
        );
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = async () => {
  try {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      return getOrRegisterServiceWorker().then((serviceWorkerRegistration) => {
        return Promise.resolve(
          getToken(messagingResolve, {
            vapidKey: config.firebase_notification.VALIDKEY,
            serviceWorkerRegistration,
          })
        );
      });
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }
};
