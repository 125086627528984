import { create } from 'zustand';
import { TPersonalProfile, TCompanyProfile } from './types/profile';
import { INIT_COMPANY_PROFILE, INIT_PERSONAL_PROFILE } from './constants/profile';

type ProfileState = {
  policyValid: boolean;
  personalProfile: TPersonalProfile;
  companyProfile: TCompanyProfile;
  setPersonalProfile: (personalProfile: TPersonalProfile) => void;
  setCompanyProfile: (companyProfile: TCompanyProfile) => void;
  setPolicyValid: (valid) => void;
};

export const useProfile = create<ProfileState>((set) => ({
  policyValid: false,
  personalProfile: INIT_PERSONAL_PROFILE,
  companyProfile: INIT_COMPANY_PROFILE,
  setPersonalProfile: (personalProfile: TPersonalProfile) => set({ personalProfile }),
  setCompanyProfile: (companyProfile: TCompanyProfile) => set({ companyProfile }),
  setPolicyValid: (policyValid: boolean) => set({ policyValid }),
}));
