import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as CloseSvg } from '../svg/Close.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const CloseIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={CloseSvg} {...props} />;
};

export default CloseIcon;
