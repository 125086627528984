import iconEmpty from '@/assets/images/emptyv2.svg';

import { colorBasePrimary, GTGSButton, GTGSTypography } from '@/components/GTGS';
import { Flex, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function AccessDenied() {
  const { t } = useTranslation();
  return (
    <>
      <Result
        icon={
          <img src={iconEmpty} style={{ width: '100%', maxWidth: '389px' }} />
        }
        extra={
          <Flex justify="center" vertical align="center" gap={16}>
            <div style={{ fontSize: 56, color: colorBasePrimary }}>403</div>
            <Flex justify="center" vertical align="center" gap={4}>
              <GTGSTypography.Title level={2}>{t('common.messages.accessDenied')}</GTGSTypography.Title>
              <GTGSTypography.Title level={3} type="secondary">
                {t('common.messages.accessDeniedMessage')}
              </GTGSTypography.Title>
            </Flex>
            <Link to="/">
              <GTGSButton type="primary" size="large">
                {t('common.goToHome')}
              </GTGSButton>
            </Link>
          </Flex>
        }
      />
    </>
  );
}

AccessDenied.Layout = ({ children }: { children: any }) => children;

export default AccessDenied;
