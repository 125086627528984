import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';

// const {
//   REACT_APP_LOG_API_KEY,
//   REACT_APP_LOG_AUTH_DOMAIN,
//   REACT_APP_LOG_PROJECT_ID,
//   REACT_APP_LOG_STORAGE_BUCKET,
//   REACT_APP_LOG_MESSAGING_SENDER_ID,
//   REACT_APP_LOG_APP_ID,
//   REACT_APP_LOG_MEASUREMENT_ID,
// } = process.env;

// Config firebase app
const firebaseConfig = {
  apiKey: "REACT_APP_LOG_API_KEY",
  authDomain: "REACT_APP_LOG_AUTH_DOMAIN",
  projectId: "REACT_APP_LOG_PROJECT_ID",
  storageBucket: "REACT_APP_LOG_STORAGE_BUCKET",
  messagingSenderId: "REACT_APP_LOG_MESSAGING_SENDER_ID",
  appId: "REACT_APP_LOG_APP_ID",
  measurementId: "REACT_APP_LOG_MEASUREMENT_ID",
};

const app = initializeApp(firebaseConfig, 'analytics');

const analytics = getAnalytics(app);

export const sendAnalyticsEvent = (event: {
  name: string;
  params: Record<string, unknown>;
}) => {
  const { name, params } = event;

  logEvent(analytics, name, params);
};

export interface AnalyticsUser {
  user_id: string | undefined | null;
  user_email: string;
  cdb_app_version: string;
}

export async function setAnalyticsUser(user: AnalyticsUser) {
  console.debug('[Analytics] Updating user', user);
  setUserProperties(analytics, {
    ...user,
  });
}
