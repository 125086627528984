import {
  Pagination as AntdPagination,
  PaginationProps as AntdPaginationProps,
} from 'antd';
import styled from '@emotion/styled';
import { memo } from 'react';
interface GTGSPaginationProps extends AntdPaginationProps {}

const GTGSPaginationStyle = styled(AntdPagination)<GTGSPaginationProps>`
  .ant-pagination-item-active {
    border: none;
  }
  .ant-select-show-arrow {
    position: absolute;
    left: 0;
  }
`;

const _GTGSPagination = ({ ...rest }: GTGSPaginationProps) => {
  return <GTGSPaginationStyle {...rest} />;
};

const GTGSPagination = memo(_GTGSPagination);

export { GTGSPagination, type GTGSPaginationProps };
