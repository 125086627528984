
import { useResponsive } from '@/hooks';
import { AutoComplete, Flex, Space, Typography } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GTGSEmpty, GTGSIconN } from '../GTGS';
import { MagnifyingGlassIcon, WalletIcon } from '../GTGS/GTGSIcon';
import { searchFeatures } from './data';
import styles from './styles.module.scss';

const DesktopSearch = () => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { sm } = useResponsive();
  const openDropdownTimeout = useRef<any>(null);
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [options, setOptions] = React.useState<DefaultOptionType[]>([
    {
      label: (
        <Typography.Text style={{ color: '#A4A7AD' }}>
          {t('common.components.suggestForYou')}
        </Typography.Text>
      ),
      options: searchFeatures.map((domain) => ({
        ...domain,
        label: (
          <Flex align="center" gap={8}>
            <GTGSIconN name={domain.icon} />
            <Space>{domain.label}</Space>
          </Flex>
        ),
      })),
    },
  ]);

  const [showSearchInput, setShowSearchInput] = useState(false);

  const handleSearch = (value: string) => {
    setOptions(() => {
      const searchData = searchFeatures
        .filter((domain) =>
          domain.label.toLowerCase().includes(value.toLowerCase())
        )
        .sort(
          (a, b) =>
            a.label.toLowerCase().indexOf(value.toLowerCase()) -
            b.label.toLowerCase().indexOf(value.toLowerCase())
        );

      if (!searchData.length) return [];
      return [
        {
          label: (
            <Typography.Text style={{ color: '#A4A7AD' }}>
             {t('common.components.suggestForYou')}
            </Typography.Text>
          ),
          options: (!value ? searchFeatures.slice(0, 5) : searchData).map(
            (domain) => ({
              ...domain,
              label: (
                <Flex align="center" gap={8}>
                  <GTGSIconN name={domain.icon} />
                  <Space>{domain.label}</Space>
                </Flex>
              ),
            })
          ),
        },
      ];
    });
  };

  return (
    <>
        <div className={styles.boxWrapper}>
          <AutoComplete
            variant="filled"
            allowClear
            onChange={(value) => {
              setSearchText(value);
            }}
            value={searchText}
            autoFocus
            onSearch={handleSearch}
            placeholder="Search..."
            options={options}
            ref={searchRef}
            filterOption={(inputValue, option) =>
              option?.value
                ?.toString()
                ?.toUpperCase()
                ?.indexOf(inputValue.toUpperCase()) !== -1
            }
            notFoundContent={
              <GTGSEmpty
                description={t('common.components.noRecordfound')}
                type="transaction"
                emptyImageProps={{
                  size: 100,
                }}
                imageStyle={{
                   height: 100
                }}
              />
            }
            open={openDropdown}
            className={styles.searchbox}
            onBlur={() => {
              setShowSearchInput(false);
              setOpenDropdown(false);
              clearTimeout(openDropdownTimeout.current);
            }}
            onSelect={(value, { path }) => {
              navigate(path);
              setShowSearchInput((showSearchInput) => !showSearchInput);
              setOpenDropdown(false);
              setSearchText('');
            }}
            popupClassName={styles.dropdownSearchbox}
          />
          {/* <BICIcon name="search" className={styles.iconSearchbox} /> */}
          <MagnifyingGlassIcon className={styles.iconSearchbox} />
        </div>
     
    </>
  );
};

export default DesktopSearch;
