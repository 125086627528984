import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as InfoSvg } from '../svg/Info.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const InfoIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={InfoSvg} {...props} />;
};

export default InfoIcon;
