import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import styles from './styles.module.scss';
import { memo } from 'react';

interface GTGSPhoneNumberProps extends PhoneInputProps {}
const _GTGSPhoneNumber = ({
  country,
  ...rest
}: GTGSPhoneNumberProps) => {
  return (
    <PhoneInput
      containerClass={styles.containerPhoneInput}
      dropdownClass={styles.dropdownFlag}
      country={country}
      {...rest}
    />
  );
};

const GTGSPhoneNumber = memo(_GTGSPhoneNumber);

export { GTGSPhoneNumber, type GTGSPhoneNumberProps };
