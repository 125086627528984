import {
  ClipboardIcon,
  ClockIcon,
  UserCircleGearIcon,
  UserCircleIcon
} from '@/components/GTGS/GTGSIcon';
import ChartCenteredTextIcon from '@/components/GTGS/GTGSIcon/icons/ChartCenteredTextIcon';
import ChartPieSliceIcon from '@/components/GTGS/GTGSIcon/icons/ChartPieSliceIcon';
import GearFineIcon from '@/components/GTGS/GTGSIcon/icons/GearFineIcon';
import { routesPath } from '@/router/constants';
import colors from '@/theme/colors.module.scss';
import { ApartmentOutlined, AppstoreOutlined, BarChartOutlined, BarsOutlined, BookOutlined, ClusterOutlined, ContactsOutlined, FormOutlined, NotificationOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// ===========================================================================
// MAIN
// ===========================================================================

type MenuItem = Required<MenuProps>['items'][number];

export const useMenuData = () => {
  const { t } = useTranslation();
  const _menuConstant = useMemo(() => {
    return {
      BOOKMARK: {
        title: t('common.menu.bookmark'),
        url: routesPath.BOOKMARK,
        code: routesPath.BOOKMARK,
        icon: <BookOutlined />
      },
      WORKSPACE: {
        title: t('common.menu.workspace'),
        url: routesPath.WORKSPACE,
        code: routesPath.WORKSPACE,
        icon: <AppstoreOutlined />
      },
      CHANNELS: {
        title: t('common.menu.channels'),
        url: routesPath.CHANNELS,
        code: routesPath.CHANNELS,
        icon: <ContactsOutlined />
      },
      CRM: {
        title: t('common.menu.crm'),
        url: routesPath.CRM,
        code: routesPath.CRM,
        icon: <ContactsOutlined />
      },
      MARKETING: {
        title: t('common.menu.marketing'),
        url: routesPath.MARKETING,
        code: routesPath.MARKETING,
        icon: <NotificationOutlined />
      },
      CONTENT: {
        title: t('common.menu.content'),
        url: routesPath.CONTENT,
        code: routesPath.CONTENT,
        icon: <FormOutlined />
      },
      AUTOMATIONS: {
        title: t('common.menu.automations'),
        url: routesPath.AUTOMATIONS,
        code: routesPath.AUTOMATIONS,
        icon: <ClusterOutlined />
      },
      REPORTING: {
        title: t('common.menu.reporting'),
        url: routesPath.REPORTING,
        code: routesPath.REPORTING,
        icon: <BarChartOutlined />
      },
      WEBSITE_PAGE: {
        title: t('common.menu.websitePage'),
        url: routesPath.WEBSITE_PAGE,
        code: routesPath.WEBSITE_PAGE,
        icon: <BarChartOutlined />
      },
      LANDING_PAGES: {
        title: t('common.menu.landingPage'),
        url: routesPath.LANDING_PAGES,
        code: routesPath.LANDING_PAGES,
        icon: <BarChartOutlined />
      },
      BLOG: {
        title: t('common.menu.blog'),
        url: routesPath.BLOG,
        code: routesPath.BLOG,
        icon: <BarChartOutlined />
      },
      MESSAGING: {
        title: t('common.menu.chat'),
        url: routesPath.MESSAGING,
        code: routesPath.MESSAGING,
        icon: <BarChartOutlined />
      },
    };
  }, [t]);
  
  const _getLabel = useCallback((label: string, url: string) => {
    if (url) {
      return <Link to={url}>{label}</Link>;
    }
    return label;
  }, []);

  const _menuItems = useMemo(() => {
    return [
      {
        key: _menuConstant.BOOKMARK.code,
        label: _getLabel(
          _menuConstant.BOOKMARK.title,
          _menuConstant.BOOKMARK.url
        ),
        icon: _menuConstant.BOOKMARK.icon,
      },
      {
        key: _menuConstant.WORKSPACE.code,
        label: _getLabel(
          _menuConstant.WORKSPACE.title,
          _menuConstant.WORKSPACE.url
        ),
        icon: _menuConstant.WORKSPACE.icon,
      },
      {
        key: _menuConstant.CRM.code,
        label: _getLabel(
          _menuConstant.CRM.title,
          _menuConstant.CRM.url
        ),
        icon: _menuConstant.CRM.icon,
      },
      {
        key: _menuConstant.CHANNELS.code,
        label: _getLabel(
          _menuConstant.CHANNELS.title,
          _menuConstant.CHANNELS.url
        ),
        icon: _menuConstant.CHANNELS.icon,
      },
      {
        key: _menuConstant.MESSAGING.code,
        label: _getLabel(
          _menuConstant.MESSAGING.title,
          _menuConstant.MESSAGING.url
        ),
        icon: _menuConstant.MESSAGING.icon,
      },
      {
        key: _menuConstant.MARKETING.code,
        label: _getLabel(
          _menuConstant.MARKETING.title,
          _menuConstant.MARKETING.url
        ),
        icon: _menuConstant.MARKETING.icon,
      },
      {
        key: _menuConstant.CONTENT.code,
        label: _getLabel(
          _menuConstant.CONTENT.title,
          _menuConstant.CONTENT.url
        ),
        icon: _menuConstant.CONTENT.icon,
        children: [
          {
            key: _menuConstant.WEBSITE_PAGE.code,
            label: _getLabel(
              _menuConstant.WEBSITE_PAGE.title,
              _menuConstant.WEBSITE_PAGE.url
            ),
            icon: _menuConstant.WEBSITE_PAGE.icon,
          },
          {
            key: _menuConstant.LANDING_PAGES.code,
            label: _getLabel(
              _menuConstant.LANDING_PAGES.title,
              _menuConstant.LANDING_PAGES.url
            ),
            icon: _menuConstant.LANDING_PAGES.icon,
          },
          {
            key: _menuConstant.BLOG.code,
            label: _getLabel(
              _menuConstant.BLOG.title,
              _menuConstant.BLOG.url
            ),
            icon: _menuConstant.BLOG.icon,
          }

        ]
      },
      {
        key: _menuConstant.AUTOMATIONS.code,
        label: _getLabel(
          _menuConstant.AUTOMATIONS.title,
          _menuConstant.AUTOMATIONS.url
        ),
        icon: _menuConstant.AUTOMATIONS.icon,
      },
      {
        key: _menuConstant.REPORTING.code,
        label: _getLabel(
          _menuConstant.REPORTING.title,
          _menuConstant.REPORTING.url
        ),
        icon: _menuConstant.REPORTING.icon,
      },
      // {
      //   key: _menuConstant.ADMINISTRATION.code,
      //   label: _getLabel(
      //     _menuConstant.ADMINISTRATION.title,
      //     _menuConstant.ADMINISTRATION.url
      //   ),
      //   icon: _menuConstant.ADMINISTRATION.icon,
      //   children: [
      //     {
      //       key: _menuConstant.USER_BEP.code,
      //       label: _getLabel(
      //         _menuConstant.USER_BEP.title,
      //         _menuConstant.USER_BEP.url
      //       ),
      //       icon: _menuConstant.USER_BEP.icon,
      //     }
      //   ]
      // }
    ] as MenuItem[];
  }, [_menuConstant, _getLabel]);

  return {
    constant: _menuConstant,
    items: _menuItems,
  };
};
