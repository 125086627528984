import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as FilesSvg } from '../svg/Files.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const FilesIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={FilesSvg} {...props} />;
};

export default FilesIcon;
