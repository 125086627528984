import ScrollToTop from '@/components/ScrollToTop';
import { getFirebaseToken, messaging } from '@/helpers/firebase';
import theme from '@/theme/themeConfig';
import { ConfigProvider, notification } from 'antd';
import { onMessage } from 'firebase/messaging';
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { GTGSTypography, GTGSIconN } from './components/GTGS';
import { WarningIcon } from './components/GTGS/GTGSIcon';
import MainLayout from './components/Layout';
import config from './config';
import { setAnalyticsUser } from './helpers/analytics';
import UserService from './services/UserService';
import { initDateTimeSetup } from './utils/dateUtils';
initDateTimeSetup();
function App() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
            keepPreviousData: true,
          },
        },
      }),
    []
  );

  const [api, contextHolder] = notification.useNotification({ top: 56 });

  const onClickNotification = useCallback(
    async (data: any) => {
      try {
        const notificationContent = JSON.parse(data?.notificationContent);

        const { entityId } = data;
        // const { res } = await fetchRequestById(entityId);
        navigate(`/requests?tab=allRequest&id=${entityId}`);
        api.destroy();
      } catch (error) {
        console.log(error);
      }
    },
    [api, navigate]
  );

  const onMessageListener = (async () => {
    const icon = {
      PENDING: <GTGSIconN name="pending" size="small" />,
      REJECTED: <GTGSIconN name="cancel" size="small" />,
      APPROVED: <GTGSIconN name="success" size="small" />,
    };
    const messagingResolve = await messaging;
    if (messagingResolve) {
      onMessage(messagingResolve, (payload) => {
        api.info({
          message: (
            <GTGSTypography.Text strong style={{ color: '#fff' }}>
              {payload?.notification?.title}
            </GTGSTypography.Text>
          ),
          description: (
            <GTGSTypography.Paragraph
              ellipsis={{ rows: 2, expandable: false }}
              style={{
                marginBottom: 0,
                fontSize: 14,
                color: '#fff',
                paddingRight: 48,
              }}
            >
              {payload?.notification?.body}
            </GTGSTypography.Paragraph>
          ),
          icon: icon[payload?.data?.notificationType ?? 'WARNING'],
          closeIcon: <WarningIcon />, //TODO: Change icon <BICIcon name="close" fill="#fff" />,
          placement: 'topRight',
          style: {
            backgroundColor: '#213051',
            borderRadius: 4,
            color: '#fff',
            cursor: 'pointer',
          },
          duration: 10,
          onClick: () => onClickNotification(payload?.data),
        });

        queryClient.invalidateQueries(['infiniteNotifications']);
        queryClient.invalidateQueries(['unreadNotifications']);

        // Sync data
        // if (payload?.data?.entityType === 'ApprovalRequest') {
        //   if (
        //     pathname === '/' &&
        //     payload?.data?.notificationType === 'PENDING'
        //   ) {
        //     return queryClient.invalidateQueries(['fetchAllRequests']);
        //   }

        //   if (
        //     pathname === '/requests' &&
        //     search === `?tab=${CTAB.pending}` &&
        //     payload?.data?.notificationType === 'PENDING'
        //   ) {
        //     return queryClient.invalidateQueries([
        //       `fetchRequest${CTAB.pending}`,
        //     ]);
        //   }
        //   if (
        //     pathname === '/requests' &&
        //     search === `?tab=${CTAB.allRequest}`
        //   ) {
        //     return queryClient.invalidateQueries([
        //       `fetchRequest${CTAB.allRequest}`,
        //     ]);
        //   }
        // } else {
        //   //Force reload account list when receive notification balance change
        //   queryClient.invalidateQueries(['getListAccountByMe']);
        //   queryClient.invalidateQueries(['fetchBankAccounts']);
        //   return;
        // }
        // if (payload?.data?.entityType === 'BALANCE_ALARM') {
        //   return queryClient.invalidateQueries(['getListAccountByMe']);
        // }
      });
    }
  })();

  // const handleGetFirebaseToken = () => {
  //   getFirebaseToken().then((firebaseToken: string | undefined) => {
  //     if (firebaseToken) {
  //       // console.log(firebaseToken);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   if (
  //     'Notification' in window &&
  //     window.Notification?.permission === 'granted'
  //   ) {
  //     handleGetFirebaseToken();
  //   }
  // }, [api, onClickNotification, pathname, queryClient, search]);

  useLayoutEffect(() => {
    // httpService.configure();
    const dataUser: any = UserService.getTokenParsed();
    if (dataUser?.sub) {
      setAnalyticsUser({
        user_id: dataUser?.sub,
        user_email: dataUser?.email,
        cdb_app_version: `${config.common.VERSION}`,
      });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider theme={theme}>
        {/* <MetaData /> */}
        <ScrollToTop />
        {UserService.isLoggedIn() && (
          <MainLayout>
            {contextHolder}
            <Outlet />
          </MainLayout>
        )}
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
