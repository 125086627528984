import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as EmptyPatternSvg } from '../svg/EmptyPattern.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const EmptyPatternIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={EmptyPatternSvg} {...props} />;
};

export default EmptyPatternIcon;
