import {
  Divider,
  Flex,
  InputNumber as AntdInputNumber,
  InputNumberProps as AntdInputNumberProps,
  Space,
  Typography,
  FormItemProps,
} from 'antd';
import styles from './styles.module.scss';
import { memo, ReactNode } from 'react';

interface GTGSInputNumberProps extends AntdInputNumberProps {
  unitPosition?: 'start' | 'end';
  unit?: string;
  name?: string;
  itemProps?: FormItemProps;
  unitComponent?: ReactNode;
}

function isTextCharacter(char) {
  return /^[a-zA-Z]$/.test(char);
}
function isNumber(str) {
  const cleanedStr = str.replace(/,/g, '');
  const numberPattern = /^-?\d+(\.\d+)?$/;
  const isValidNumber = numberPattern.test(cleanedStr);
  if (isValidNumber) {
    const parts = cleanedStr.split('.');
    try {
      BigInt(parts[0]);
      if (parts.length > 1) {
        BigInt(parts[1]);
      }

      return true;
    } catch (e) {
      return false;
    }
  }

  return false;
}
const _GTGSInputNumber = (props: GTGSInputNumberProps) => {
  const {
    unit,
    unitComponent,
    name,
    itemProps,
    unitPosition = 'start',
    ...rest
  } = props;

  return (
    <Space.Compact className={styles.inputWithSelect}>
      <Flex
        style={{
          order: unitPosition === 'end' ? 0 : 2,
          width: '100%',
          ...itemProps?.style,
        }}
        name={name}
        {...itemProps}
      >
        <AntdInputNumber
          stringMode
          controls={false}
          inputMode="text"
          variant="borderless"
          style={{ height: 38, width: '100%', ...rest.style }}
          onPaste={(e) => {
            const pastedText = e.clipboardData.getData('text');
            if (!isNumber(pastedText)) {
              e.preventDefault();
            }
          }}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (isTextCharacter(e.key) && !e.ctrlKey) {
              e.preventDefault();
            }
            rest.onKeyDown?.(e);
          }}
          {...rest}
        />
      </Flex>
      <Divider
        type="vertical"
        style={{
          height: '24px',
          width: '2px',
          margin: 'auto',
          borderInlineWidth: 2,
          order: 1,
        }}
      />
      <Flex
        align="center"
        justify="center"
        style={{ order: unitPosition === 'end' ? 2 : 0 }}
      >
        {unitComponent ? (
          unitComponent
        ) : (
          <Typography.Text style={{ width: 48, textAlign: 'center' }}>
            {unit}
          </Typography.Text>
        )}
      </Flex>
    </Space.Compact>
  );
};


const GTGSInputNumber = memo(_GTGSInputNumber);

export { GTGSInputNumber, type GTGSInputNumberProps };
