import { Loading } from '@/components';
import loadable from '@loadable/component';
import { routesPath } from './constants';

const MessagingPage = loadable(() => import('@/pages/Messaging'), {
  fallback: <Loading />,
});

// --------------------------------------------------------------------------- ROUTER
const RouterMessaging = {
  path: routesPath.MESSAGING,
  children: [
    {
      path: routesPath.MESSAGING,
      element: <MessagingPage />,
    },
  ],
};

export default RouterMessaging;
