import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import colors from '@/theme/colors.module.scss';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;
type IconProviderProps = Partial<CustomIconComponentProps>;

const IconProvider = (props: Partial<IconProviderProps>) => {
  const { style, component, ...rest } = props;
  const fontSize = style?.fontSize || 24;
  const color = style?.color || colors.colorTextActiveIcon;
  // const color = style?.color || 'inherit';

  return (
    <AntdIcon
      component={component}
      style={{ fontSize, color, ...style }}
      {...rest}
    />
  );
};

export default IconProvider;
