import { Loading } from '@/components';
import loadable from '@loadable/component';
import { routesPath } from './constants';

const CasesPage = loadable(() => import('@/pages/Cases'), {
  fallback: <Loading />,
});

// --------------------------------------------------------------------------- ROUTER
const RouterContent = {
  path: routesPath.CONTENT,
  children: [
    {
      path: routesPath.CONTENT,
      element: <CasesPage />,
    },
  ],
};

export default RouterContent;
