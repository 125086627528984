import { ReactElement, Suspense } from 'react';
import { Layout, theme as themeAntd } from 'antd';
import SiderLayout from '../Sider/Sider';
import styles from './Layout.module.scss';
import { Loading } from '@/components';
import { GTGSPageHeader, GTGSTypography } from '@/components/GTGS';

const { Content } = Layout;

interface LayoutGuidelineProps {
  children: ReactElement;
}
const LayoutGuideline = (props: LayoutGuidelineProps) => {
  const { children } = props;
  const {
    token: { colorBgContainer },
  } = themeAntd.useToken();
  return (
    <Layout hasSider>
      <SiderLayout />
      <Layout style={{ marginLeft: 200 }} className={styles.layoutContent}>
        <Suspense fallback={<Loading />}>
            {' '}
          <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
            <div
              style={{
                background: colorBgContainer,
              }}
              className={styles.content}
            >
              {children}
            </div>
          </Content>
        </Suspense>
      </Layout>
    </Layout>
  );
};

export default LayoutGuideline;
