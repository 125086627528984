import { useResponsive } from '@/hooks';
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, theme } from 'antd';
import { BasicProps } from 'antd/es/layout/layout';
import React, { ReactNode } from 'react';
import { GTGSPageHeader } from '../GTGS/GTGSPageHeader/GTGSPageHeader';
import { LeftSlider, SIDER_WIDTH } from './LeftSider';
interface MainLayoutProps extends BasicProps {
  children: ReactNode;
}
const {  Content} = Layout;

const items: MenuProps['items'] = [
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  BarChartOutlined,
  CloudOutlined,
  AppstoreOutlined,
  TeamOutlined,
  ShopOutlined,
].map((icon, index) => ({
  key: String(index + 1),
  icon: React.createElement(icon),
  label: `nav ${index + 1}`,
}));

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  ...rest
}: MainLayoutProps) => {
  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  const { md } = useResponsive();
  return (
    <Layout hasSider>
      <LeftSlider />
      <Content
        style={{
          padding: 0,
          overflow: 'initial',
          background: '#F1F3F5',
        }}
      >
        <Layout style={{ marginLeft: md ? SIDER_WIDTH : 0 }}>
          <GTGSPageHeader />
          <Content
            style={{
              padding: '24px',
              overflow: 'initial',
              borderRadius: '24px 0 0',
              background: '#F1F3F5',
            }}
          >
            <div
              style={{
                borderRadius: borderRadiusLG,
                minHeight: 'calc(100vh - 56px - 48px)',
              }}
            >
              {children}
            </div>
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
};

export default MainLayout;
