export const routesMenu = {
    DASHBOARD : '',
    BOOKMARK:"bookmark",
    WORKSPACE: "workspace",
    CRM: "crm",
    MARKETING: "marketing",
    CONTENT: "content",
    REPORTING: "reporting",
    AUTOMATIONS: "automations",
    MESSAGING: "messaging",
    CHANNELS: "channels",
  };
  
  export const routesPath = {
    DASHBOARD : `/${routesMenu.DASHBOARD}`,
    BOOKMARK : `/${routesMenu.BOOKMARK}`,
    WORKSPACE : `/${routesMenu.WORKSPACE}`,
    CRM : `/${routesMenu.CRM}`,
    MARKETING : `/${routesMenu.MARKETING}`,
    CONTENT : `/${routesMenu.CONTENT}`,
    REPORTING : `/${routesMenu.REPORTING}`,
    AUTOMATIONS : `/${routesMenu.AUTOMATIONS}`,
    WEBSITE_PAGE : `/${routesMenu.CONTENT}/website-page`,
    LANDING_PAGES : `/${routesMenu.CONTENT}/landing-pages`,
    BLOG : `/${routesMenu.CONTENT}/blog`,
    MESSAGING : `/${routesMenu.MESSAGING}`,
    CHANNELS : `/${routesMenu.CHANNELS}`,


  }