/* eslint-disable react-refresh/only-export-components */
import { lazy } from 'react';

// ===================================================

const ButtonPage = lazy(() => import('./pages/Button/ButtonPage'));
const ChipPage = lazy(() => import('./pages/Chip/ChipPage'));
const TypographyPage = lazy(() => import('./pages/Typography/TypographyPage'));
const CheckboxPage = lazy(() => import('./pages/Checkbox/CheckboxPage'));
const CardPage = lazy(() => import('./pages/Card/CardPage'));
const StatusPage = lazy(() => import('./pages/Status/StatusPage'));
const IconPage = lazy(() => import('./pages/Icon/IconPage'));
const InputPage = lazy(() => import('./pages/Input/InputPage'));
const ColorPage = lazy(() => import('./pages/Color/ColorPage'));
const TablePage = lazy(() => import('./pages/Table/TablePage'));
const TabsPage = lazy(() => import('./pages/Tabs/TabsPage'));
const MessagePage = lazy(() => import('./pages/Message/MessagePage'));

// ====================================================
export const RouterGuideline = [
  {
    path: '/guideline/color',
    element: <ColorPage />,
    title: 'Color',
  },
  {
    path: '/guideline/typography',
    element: <TypographyPage />,
    title: 'Typography',
  },
  {
    path: '/guideline/icon',
    element: <IconPage />,
    title: 'Icon',
  },
  {
    path: '/guideline/card',
    element: <CardPage />,
    title: 'Card',
  },
  {
    path: '/guideline/button',
    element: <ButtonPage />,
    title: 'Button',
  },
  {
    path: '/guideline/chip',
    element: <ChipPage />,
    title: 'Chip',
  },
  {
    path: '/guideline/checkbox',
    element: <CheckboxPage />,
    title: 'Checkbox',
  },
  {
    path: '/guideline/input',
    element: <InputPage />,
    title: 'Input field',
  },
  {
    path: '/guideline/table',
    element: <TablePage />,
    title: 'Table',
  },
  {
    path: '/guideline/message',
    element: <MessagePage />,
    title: 'Message',
  },
  {
    path: '/guideline/status',
    element: <StatusPage />,
    title: 'Status',
  },
  {
    path: '/guideline/tabs',
    element: <TabsPage />,
    title: 'Tabs',
  },
];
