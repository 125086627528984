import { ConfigProvider } from 'antd';
import LayoutGuideline from './Components/Layout';
import theme from '@/theme/themeConfig';
import { Outlet } from 'react-router-dom';

const Guideline = () => {
  return (
    <ConfigProvider theme={theme}>
      <LayoutGuideline>
        <Outlet />
      </LayoutGuideline>
    </ConfigProvider>
  );
};

export default Guideline;
