/* eslint-disable no-underscore-dangle */
const getEnv = (key: string): string => {
  return process.env[key] || '';
};

const config = {
  gatewayUrl: getEnv('REACT_APP_GATEWAY_API_URL'), 
  common: {
    COMMON_API_URL: getEnv('REACT_APP_GATEWAY_API_URL'),
    VERSION: getEnv('REACT_APP_VERSION'),
    FACEBOOK_APP_ID: getEnv('REACT_APP_FACEBOOK_APP_ID'),
  },
  // Keycloak
  keycloak: {
    ENDPOINT: getEnv('REACT_APP_KEYCLOAK_ENDPOINT'),
    REALM: getEnv('REACT_APP_KEYCLOAK_REALM'),
    CLIENTID: getEnv('REACT_APP_KEYCLOAK_CLIENTID'),
  },
  firebase_notification: {
    API_URL: getEnv('REACT_APP_GATEWAY_API_URL'),
  },
};

export default config;
