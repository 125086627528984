import AntdIcon from '@ant-design/icons';
import type { GetProps } from 'antd';
import { ReactComponent as QuestionSvg } from '../svg/Question.svg';

import IconProvider from '../IconProvider';

type CustomIconComponentProps = GetProps<typeof AntdIcon>;

const QuestionIcon = (props: Partial<CustomIconComponentProps>) => {
  return <IconProvider component={QuestionSvg} {...props} />;
};

export default QuestionIcon;
