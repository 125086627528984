import React from 'react';
import classNames from 'classnames';

// ----------------------------------------
interface GTGSContainerProps {
  children: React.ReactNode;
  className?: string;
}
//==================  MAIN =================

const GTGSContainer = ({ children, className }: GTGSContainerProps) => (
  <div className={classNames(className)}>{children}</div>
);

export { GTGSContainer, type GTGSContainerProps };
