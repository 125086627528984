import styled from '@emotion/styled';
import { Alert as AntdAlert, AlertProps as AntdAlertProps } from 'antd';
import {
  CheckCircleIcon,
  CloseIcon,
  InfoIcon,
  WarningDiamondIcon,
  WarningIcon,
} from '../GTGSIcon';
import colors from '@/theme/colors.module.scss';
import QuestionIcon from '../GTGSIcon/icons/QuestionIcon';
import { memo } from 'react';

interface GTGSAlertProps extends AntdAlertProps {}

const GTGSAlertStyle = styled(AntdAlert)<GTGSAlertProps>`
  .ant-alert-message {
    font-weight: bold;
  }
  .ant-alert-description {
    font-size: 14px;
  }
  .ant-alert-icon {
    margin-top: auto;
    margin-bottom: auto;
  }
  ${(props: GTGSAlertProps) =>
    props.type === undefined &&
    `background: ${colors.colorSurfaceSecondaryDefault};`}
`;

const _GTGSAlert = ({
  type,
  closable,
  icon,
  showIcon = true,
  ...rest
}: GTGSAlertProps) => {
  const _icon = {
    success: <CheckCircleIcon style={{ color: colors.colorSuccessDark }} />,
    info: <InfoIcon style={{ color: colors.colorInfoNormal }} />,
    warning: <WarningIcon style={{ color: colors.colorWarningNormal }} />,
    error: <WarningDiamondIcon style={{ color: colors.colorDangerNormal }} />,
    undefined: <QuestionIcon style={{ color: colors.colorTextActiveIcon }} />,
  }[type as string];

  return (
    <GTGSAlertStyle
      showIcon={showIcon}
      icon={icon ? icon : _icon}
      type={type}
      closable={
        closable
          ? {
              'aria-label': 'close',
              closeIcon: <CloseIcon style={{ fontSize: 16 }} />,
            }
          : false
      }
      {...rest}
    />
  );
};

const GTGSAlert = memo(_GTGSAlert);

export { GTGSAlert, type GTGSAlertProps };
